import React,{useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Icon } from '@iconify/react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { postEnquirySave, postStateSave, postCitySave,postGeneralData } from "../services/serviceapi";
// import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [states,setStates] = useState([]);
  const [cities,setCities] = useState([]);

  const [country, setCountry] = useState("India");
  const [pincode, setPincode] = useState("");
  const [customer_type, setCustomerType] = useState("");
  const [address, setAddress] = useState("");
  const [comments, setComments] = useState("");
  const defData = {name:'',email:'',mobile:'',state:'',city:'',country:'',pincode:'',customer_type:'',address:'',comments:''};
  const [formErrors,formError] = useState(defData);
  const [showEnquiry,setShowEnquiry] = useState(false);
  const [enquirySubmit, setEnquirySubmit] = useState("");
  const [companyname,setCompanyName] = useState("");
	const [contactnumber,setContactNumber] = useState("");
	const [generaladdress,setGeneralAddress] = useState("");
	const [generalemail,setGeneralEmail] = useState("");
  const captchaReference = useRef(null);
  const [captchaStatus, setCaptchaStatus] = useState({ success: false, message: '' })
  
  const defaultCheckClose = ()=> {
    setState('');
    setCity('');
    setCustomerType('');
    setShowEnquiry(false);
  }
  const onChangeCaptcha = (value)=>{
    console.log("Captcha value :", value);

  }
  const onChange = (e) => {
    const eTarget = e.target;
    switch(eTarget.name) {
    case 'name':
      setName(eTarget.value)
    break;

    case 'email':
      setEmail(eTarget.value)
    break;
    
    case 'mobile':
      setMobile(eTarget.value)
    break;

    case 'state':
      setState(eTarget.value)
    break;

    case 'city':
      setCity(eTarget.value)
    break;

    case 'country':
      setCountry(eTarget.value)
    break;

    case 'pincode':
      setPincode(eTarget.value)
    break;

    case 'customer_type':
      setCustomerType(eTarget.value)
    break;

    case 'address':
      setAddress(eTarget.value)
    break;

    case 'comments':
      setComments(eTarget.value)
    break;
    
    default:
    break;
    }
  }
  
  const onChangeState = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      // console.log(eTarget.value,'state');
      setCity('');
      setState(eTarget.value);
      fetchCity(eTarget.value);
    }else{
      // console.log(eTarget.value,'state1');
      setState(eTarget.value);
      fetchCity(0);
      setCity('');
    }
    
  }
  const onChangeCity = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      // console.log(eTarget.value,'state');
      setCity(eTarget.value);
    }else{
      // console.log(eTarget.value,'state1');
      setCity('');
    }
    // console.log(eTarget.value,'city');
    // setCity(eTarget.value);
  }
  const onChangeCustomerType = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setCustomerType(eTarget.value);
    }else{
      setCustomerType('')
    }
    // console.log(eTarget.value,'ctype');
    // fetchCountry(eTarget.value);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidMobileNumber(mobile)
  {
    return /^[6-9][0-9]{9}$/i.test(mobile);
  }
  function isValidPincode(pincode){
    return /^[1-9][0-9]{5}$/i.test(pincode)
  }
  const onSubmit = (e) => {
    const error = {}
    if(name ==='') error.name = 'Enter name'
    if(email!=="" && !isValidEmail(email))
    {
      error.email = ' Please enter valid email';
    }
    if(mobile ==='') error.mobile = 'Enter mobile number'
    else if(mobile!=="" && !isValidMobileNumber(mobile)) 
    {
      error.mobile = ' Please enter valid mobile number';
    }
    if(state ==='') error.state = 'Select State'
    if(city ==='') error.city = 'Select City'
    if(pincode ==='') error.pincode = 'Enter pincode'
    else if(pincode!=="" && !isValidPincode(pincode))
    {
      error.pincode = "Please enter valid pincode";
    }
    if(address ==='') error.address = 'Enter address'
    if(customer_type ==='') error.customer_type = 'Enter Customer Type'

    if(Object.keys(error).length === 0)
    {
      postEnquirySave(name,mobile,email,state,city,country,address,pincode,customer_type,comments)
      .then(response => {
        if(response.status=== 200)
        {
          // console.log("response",response);
          setEnquirySubmit(response.message);
          setShowEnquiry(true);
          setName("");
          setEmail("");
          setMobile("");
          setAddress("");
          setState("");
          setCity("");
          setPincode("");
          setCustomerType("");
          setComments("");

        }
      
    })
    // setState("");
    // setCity("");
    // setCustomerType("");

    };
    formError(error)
  }
 
  const getState = ()=> {
    const content = [];
    states.map((value,index) => (
      content.push(<option key={index} value={value.state_id}>{value.state_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example" onChange = {onChangeState} name="state" value={state}> <option value="">Select State</option>{content}</Form.Select></>;
  }
  
  const getCity = ()=> {
    const content = [];
    cities.map((value,index) => (
      content.push(<option key={index} value={value.city_id}>{value.city_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example"
     onChange = {onChangeCity}
     value={city}
     name="city"> <option value="">Select City</option>{content}</Form.Select></>;
  }
  const fetchCity = (state)=>{
    postCitySave(state)
    .then(response => {
      if(response.status=== 200 && response.city_list.length > 0)
      {
        setCities(response.city_list);
      }
      else{
        setCities([]);
      }
    },)
  }
  const fetchStates = ()=> {
    postStateSave()
    .then(response => {
      if(response.status=== 200 && response.State_list.length > 0)
      setStates(response.State_list);
    },)
    fetchCity(state);
  }
  const getGeneralData = ()=>{
		postGeneralData()
		.then(response => {
		if(response.status=== 200)
		{
			setCompanyName(response.site_details.company_name);
			setContactNumber(response.site_details.contact_number);
			setGeneralAddress(response.site_details.address);
			setGeneralEmail(response.site_details.contact_email);

		}
		},)
	}

  useEffect(() => {
    fetchStates();
    getGeneralData();
  },[]);

  return (
    <div>
        <Header />
        
        <section className="inner-banner" id="topPosition">
          <Container fluid="md">
            <Row>
              <Col lg={6} className="offset-lg-3">
                <div className="inner-cont">
                  <h1>Contact Us</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                  </Breadcrumb>		
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section contact-section pb-3">
          <Container fluid="md">
            <Row>            
              <Col lg={4}>
                <div className="contact-content">
                  <p>For Phone Queries</p>
                  <h5><Link href="tel:+91 9677262734"><Icon icon="ph:device-mobile-speaker" /> {contactnumber}</Link></h5>
                </div>       
              </Col>
              <Col lg={4}>
                <div className="contact-content">
                  <p>For Email Queries</p>
                  <h5><Link href="mailto:info@mayilonfoods.com"><Icon icon="material-symbols:mail-outline-rounded" /> {generalemail}</Link> </h5>
                </div> 
              </Col>
              <Col lg={4}>
                <div className="contact-content">
                  <p>For Address</p>
                  <h5>{generaladdress}</h5>
                </div>       
              </Col>
            </Row>
          </Container>
        </section>

        <section className="contact-forms">
          <Container fluid="md">            
            <div className='form-style1 enquirybox'>
              <h3 className="mb-3">For Enquiry</h3>
              <form>
              <Row>
                      <Col lg={8}>
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="form-group" controlId="">
                                <Form.Label>Name <span className="req">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" onChange = {onChange} maxLength="20" name="name" value={name} />
                                {formErrors.name!=='' && formErrors.name!==undefined && (<Form.Text className='error text-danger'>{formErrors.name}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={4}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Email ID <span className="req"></span></Form.Label>
                                <Form.Control type="email" placeholder="Enter Email ID" onChange = {onChange} maxLength="20" name="email" value={email} />
                                {formErrors.email!=='' && formErrors.email!==undefined && (<Form.Text className='error text-danger'>{formErrors.email}</Form.Text>)}
                              </Form.Group>
                            </Col>

                          <Col lg={4}>
                            <Form.Group className="form-group" controlId="">
                              <Form.Label>Mobile No. <span className="req">*</span></Form.Label>
                              <Form.Control type="text" maxLength = "10" placeholder="Enter Mobile No." onChange = {onChange} name="mobile" value={mobile} />
                              {formErrors.mobile!=='' && formErrors.mobile!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobile}</Form.Text>)}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-group" controlId="">
                              <Form.Label>Country <span className="req">*</span></Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option value="India">India</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          
                          <Col lg={4}>
                            <Form.Group className="form-group" controlId="">
                              <Form.Label>State <span className="req">*</span></Form.Label>
                              {getState()}
                              {formErrors.state!=='' && formErrors.state!==undefined && (<Form.Text className='error text-danger'>{formErrors.state}</Form.Text>)}
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-group" controlId="">
                              <Form.Label>City <span className="req">*</span></Form.Label>
                              {getCity()}
                              {formErrors.city!=='' && formErrors.city!==undefined && (<Form.Text className='error text-danger'>{formErrors.city}</Form.Text>)}
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="form-group" controlId="">
                              <Form.Label>Pincode <span className="req">*</span></Form.Label>
                              <Form.Control type="text" placeholder="Enter Pincode" name="pincode" value = {pincode} onChange = {onChange} maxLength = "6" />
                              {formErrors.pincode!=='' && formErrors.pincode!==undefined && (<Form.Text className='error text-danger'>{formErrors.pincode}</Form.Text>)}
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="form-group" controlId="">
                              <Form.Label>Customer Type <span className="req">*</span></Form.Label>
                              <Form.Select aria-label="Default select example" onChange = {onChangeCustomerType} value={customer_type}>
                                <option value=''>Select Customer Type</option>
                                <option value="customer">Customer</option>
                                <option value="dealer">Dealer</option>
                                <option value="retailer">Retailer</option>
                              </Form.Select>
                              {formErrors.customer_type!=='' && formErrors.customer_type!==undefined && (<Form.Text className='error text-danger'>{formErrors.customer_type}</Form.Text>)}
                            </Form.Group>
                          </Col>
                      </Row>
                    </Col>

                    <Col lg={4}>
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>Address <span className="req">*</span></Form.Label>
                            <Form.Control as="textarea" aria-label="Enter Address" name="address" value={address} onChange = {onChange} />
                            {formErrors.address!=='' && formErrors.address!==undefined && (<Form.Text className='error text-danger'>{formErrors.address}</Form.Text>)}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>Comments <span className="req"></span></Form.Label>
                            <Form.Control as="textarea" aria-label="Enter Comments" name="comments" value={comments} onChange = {onChange} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='captchabox mt-3'>
                        {/* <img src="./assets/images/captcha-google.gif" className="img-fluid" alt="" title=""></img> */}
                        <ReCAPTCHA
                        sitekey="6LcTqlwnAAAAAFVpSi4KS1fQ4p8Jfsf9e6PoGoKD"
                        onChange={onChangeCaptcha}
                      />
                      </div>
                      
                    </Col>

                    <Col lg={6}>
                      <div className='button-group mt-3 text-end'>
                        <Button variant="primary" size="lg" onClick = {(e)=>onSubmit()}>Send Enquiry </Button >
                      </div>
                    </Col> 
                  </Row>
              </form>
            </div>            
          </Container>
        </section>

        <section className="map-section">
          <Container>
            <Row>
              <Col lg={12}>
              <iframe title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1943.1462787306002!2d80.09378936709224!3d13.080635990327115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52898814547817%3A0x79b1948927931c83!2sMayilon%20Foods!5e0!3m2!1sen!2sin!4v1680077331835!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

        {/* Check Enquiry Modal */}
        <Modal show={showEnquiry} onHide={defaultCheckClose} size="md" className='modal-alert-option'>
          <Modal.Header closeButton>
            <Modal.Title>Enquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              {enquirySubmit}
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={(e) => defaultCheckClose()}>
              Ok
            </Button>
            {/* <Button variant="light" onClick={defaultCheckClose}>
              Cancel
            </Button> */}
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default Contact;
