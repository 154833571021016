// const baseURL='http://192.168.1.216/mayilon-admin/api/';
// const baseURL='http://192.168.1.151/mayilon-admin/api/';

// const baseURL='https://demolinc.com/mayilonfoods/api/';

const baseURL='https://dev.beetovitaa.com/admin/api/';

export async function postTestimonialListing()
{
    try
    {
        const response = await fetch(baseURL+'testimoniallisting',{
            method:'POST',
            body: JSON.stringify({
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getHomeProduct(id)
{
    try
    {
        const response = await fetch(baseURL+'homeproduct/'+id);
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getAllBanners()
{
    try
    {
        const response = await fetch(baseURL+'bannerlist');
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getAllProducts()
{
    try
    {
        const response = await fetch(baseURL+'productlist');
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getProductDetail(id)
{
    try
    {
        const response = await fetch(baseURL+'productdetails/'+id);
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postRegister(mobilenumber,password)
{
    try
    {
        const response = await fetch(baseURL+'register',
        {
            method:'POST',
            body: JSON.stringify({mobile_no:mobilenumber,password:password
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postOtpValidate(mobilenumber,otp)
{
    try
    {
        const response = await fetch(baseURL+'registerotpvalidate',
        {
            method:'POST',
            body: JSON.stringify({mobile_no:mobilenumber,register_otp:otp
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postforgotpassword(mobilenumber)
{
    try
    {
        const response = await fetch(baseURL+'forgotpassword',
        {
            method:'POST',
            body: JSON.stringify({mobile_no:mobilenumber,
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postforgotpasswordotp(mobilenumber,otp)
{
    try
    {
        const response = await fetch(baseURL+'forgotpasswordotp',
        {
            method:'POST',
            body: JSON.stringify({mobile_no:mobilenumber,forgot_password_otp:otp,
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postresetpassword(mobilenumber,password)
{
    try
    {
        const response = await fetch(baseURL+'resetpassword',
        {
            method:'POST',
            body: JSON.stringify({mobile_no:mobilenumber,password:password,
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postLogIn(mobilenumber,password,sessId)
{
    try
    {
        const response = await fetch(baseURL+'login',{
            method:'POST',
            body: JSON.stringify({log_mobile:mobilenumber,log_password:password,session_id:sessId
            // body: JSON.stringify({mobile:mobilenumber,password:password

                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postUserProfile(id)
{
    try
    {
        const response = await fetch(baseURL+'userprofile',{
            method:'POST',
            body: JSON.stringify({user_id:id,
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postUpdateProfile(id,fullname,mobile,email,dob)
{
    try
    {
        const response = await fetch(baseURL+'updateprofile',{
            method:'POST',
            body: JSON.stringify({user_id:id,email:email,user_name:fullname,user_mobile:mobile,d_o_b:dob
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCartSave(id,p_id)
{
    // console.log(p_id,'product');
    try
    {
        const response = await fetch(baseURL+'cart_save',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,product_id:p_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCartList(id)
{
    try
    {
        const response = await fetch(baseURL+'cartlist',
        {
            method:'POST',
            body: JSON.stringify({user_id:id
                // Add parameters here
            })
        });        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCartUpdate(id,product_id,cart_id,quantity)
{
    try
    {
        const response = await fetch(baseURL+'cart_update',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,product_id:product_id,cart_id:cart_id,quantity:quantity
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCartListDelete(cart_id,id)
{
    try
    {
        const response = await fetch(baseURL+'cart_delete',{
            method:'POST',
            body: JSON.stringify({cart_id:cart_id,user_id:id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCartDelete(id)
{
    try
    {
        const response = await fetch(baseURL+'cartempty',{
            method:'POST',
            body: JSON.stringify({user_id:id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCartCount(id)
{
    try
    {
        const response = await fetch(baseURL+'cartcount',{
            method:'POST',
            body: JSON.stringify({user_id:id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postStateSave()
{
    try
    {
        const response = await fetch(baseURL+'state',{
            method:'POST',
            body: JSON.stringify({
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCitySave(state_id)
{
    try
    {
        const response = await fetch(baseURL+'city',{
            method:'POST',
            body: JSON.stringify({state_id:state_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postAddressSave(id,name,mobile,email,state,city,address,address1,pincode)
{
    try
    {
        const response = await fetch(baseURL+'address_save',{
            method:'POST',
            body: JSON.stringify({user_id:id,customer_name:name,customer_mobile:mobile,customer_email:email,state:state,city:city,customer_address:address,customer_address1:address1,pincode:pincode
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postAddressList(id)
{
    try
    {
        const response = await fetch(baseURL+'addresslist',
        {
            method:'POST',
            body: JSON.stringify({user_id:id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postAddressUpdate(id,customer_add_id,customer_name,customer_mobile,customer_email,customer_address,customer_address1,state,city,pincode)
{
    try
    {
        const response = await fetch(baseURL+'address_update',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,customer_address_id:customer_add_id,customer_name:customer_name,customer_mobile:customer_mobile,customer_email:customer_email,customer_address:customer_address,customer_address1:customer_address1,state:state,city:city,pincode:pincode
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postAddressDelete(id,add_id,billingaddress_check)
{
    try
    {
        const response = await fetch(baseURL+'addressdelete',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,customer_address_id:add_id,billingaddress_check:billingaddress_check
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postAddressDefault(id,add_id)
{
    try
    {
        const response = await fetch(baseURL+'addressdefault',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,customer_address_id:add_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postShippingAddressAdd(id,customer_address_id,name,mobile,email,state,city,address,address1,pincode,billingaddress_check)
{
    try
    {
        const response = await fetch(baseURL+'shippingAddresadd',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,customer_address_id:customer_address_id,customer_name:name,customer_mobile:mobile,customer_email:email,state:state,city:city,customer_address:address,customer_address1:address1,pincode:pincode,billingaddress_check:billingaddress_check
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postShippingAddressUpdate(id,address_id,name,mobile,state,city,address,address1,pincode)
{
    try
    {
        const response = await fetch(baseURL+'shippingupdate',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,customer_address_id:address_id,customer_name:name,customer_mobile:mobile,state:state,city:city,customer_address:address,customer_address1:address1,pincode:pincode
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postBillingAddressDetails(id,billing_id)
{
    try
    {
        const response = await fetch(baseURL+'billing_address_details',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,billing_id:billing_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postOrderCheckOut(id,add_id)
{
    try
    {
        const response = await fetch(baseURL+'ordercheckout',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,ship_id:add_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postCouponCode(id,coupon_code)
{
    try
    {
        const response = await fetch(baseURL+'checkcouponcode',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,coupon_code:coupon_code
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postEnquirySave(name,mobile,email,state,city,country,address,pincode,customer_type,comments)
{
    try
    {
        const response = await fetch(baseURL+'enquirysave',
        {
            method:'POST',
            body: JSON.stringify({name:name,mobile:mobile,email:email,state:state,city:city,country:country,address:address,pincode:pincode,customer_type:customer_type,comments:comments
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postOrderConfirm(id,coupon_code,bill_id,ship_id,delivery_charge,transaction_id)
{
    try
    {
        const response = await fetch(baseURL+'orderconfirm',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,coupon_code:coupon_code,billing_address_id:bill_id,shipping_address_id:ship_id,delivery_charge:delivery_charge,transaction_id:transaction_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error)
    {
        return [];
    }
}
export async function postOrderFail(id)
{
    try
    {
        const response = await fetch(baseURL+'orderfailed',
        {
            method:'POST',
            body: JSON.stringify({user_id:id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error)
    {
        return [];
    }
}
export async function postOrderListing(id)
{
    try
    {
        const response = await fetch(baseURL+'orderlisting',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
// export async function postOrderDetails(id,invoice_id)
// {
//     try
//     {
//         const response = await fetch(baseURL+'orderdetails',
//         {
//             method:'POST',
//             body: JSON.stringify({user_id:id,invoice_id:invoice_id
//                 // Add parameters here
//             })
//         });
//         return await response.json();
//     }
//     catch(error) 
//     {
//         return [];
//     }
// }
export async function getOrderDetail(invoice_id)
{
    try
    {
        const response = await fetch(baseURL+'orderdetails?invoice_id='+invoice_id);
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getCMSDetail(cms_url)
{
    try
    {
        const response = await fetch(baseURL+'cmsdetails?cms_url='+cms_url);
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getPrivacyPolicy(page_url)
{
    try
    {
        const response = await fetch(baseURL+'cmsdetails?cms_url='+page_url);
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function getShippingPolicy(page_url)
{
    try
    {
        const response = await fetch(baseURL+'cmsdetails?cms_url='+page_url);
        return await response.json();
    }
    catch(error)
    {
        return [];
    }
}
export async function getReturnPolicy(page_url)
{
    try
    {
        const response = await fetch(baseURL+'cmsdetails?cms_url='+page_url);
        return await response.json();
    }
    catch(error)
    {
        return [];
    }
}
export async function getTermsCondition(page_url)
{
    try
    {
        const response = await fetch(baseURL+'cmsdetails?cms_url='+page_url);
        return await response.json();
    }
    catch(error)
    {
        return [];
    }
}
export async function postChangePassword(id,old_password,new_password)
{
    try
    {
        const response = await fetch(baseURL+'changepassword',
        {
            method:'POST',
            body: JSON.stringify({user_id:id,old_password:old_password,new_password:new_password
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postSaveGuestUser(guest_name,guest_mobile)
{
    try
    {
        const response = await fetch(baseURL+'saveGuestUser',
        {
            method:'POST',
            body: JSON.stringify({guest_name:guest_name,guest_mobile:guest_mobile
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postResendOTP(mobile,forgotOTP)
{
    try
    {
        const response = await fetch(baseURL+'resentotp',
        {
            method:'POST',
            body: JSON.stringify({mobile_no:mobile,resent_otp_for:forgotOTP
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postGuestOTPValidate(guest_mobile,guest_otp,session_id,guest_id)
{
    try
    {
        const response = await fetch(baseURL+'guestotpvalidate',
        {
            method:'POST',
            body: JSON.stringify({guest_mobile:guest_mobile,guest_otp:guest_otp,session_id:session_id,cur_guestid:guest_id
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}
export async function postGeneralData()
{
    try
    {
        const response = await fetch(baseURL+'generaldata',
        {
            method:'POST',
            body: JSON.stringify({
                // Add parameters here
            })
        });
        return await response.json();
    }
    catch(error) 
    {
        return [];
    }
}

