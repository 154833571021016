import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SideMenu from "../Components/SideMenu";

const OrderDetails = () => { 

  return (
    <div>
        <Header />
        <section className="section header-nxt my-orders">
          <Container fluid="md">
            <Row>            
              <Col lg={3} md={3}>
                <SideMenu />                
              </Col>
              <Col lg={9} md={9}>
                <div className='account-right'>
                  <h3 className='main-heading2'>Track Orders</h3>
                  <div className='order-details-card mb-4'>
                    <div className='track-search'>
                      <Row>
                        <Col lg={6} md={8}>
                          <Form.Group className="form-group" controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Enter Order ID" />
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={4}>
                          <div className='button-group'>
                            <Button variant="primary" className="btn-lg" type="submit">Track</Button>
                            <Button variant="secondary" className="btn-lg" type="submit">Clear</Button>       
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className='track-cover mt-0'>
                      <div className='order-track'>
                        <div className="timeline">
                          <ul>
                            <li className="active">
                              <div className="left-side">
                                  <p className="date-text">Mar 20, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Delivered</p>
                                <p>Your product is delivered.</p>
                              </div>
                            </li>
                            <li className="">
                              <div className="left-side">
                                  <p className="date-text">Mar 18, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Out for Delivery</p>
                                <p>Your product is Out for Delivery</p>
                              </div>
                            </li>
                            <li className="">
                              <div className="left-side">
                                  <p className="date-text">Mar 12, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Ready to Despatch</p>
                                <p>Your product is Ready to Despatch.</p>
                              </div>
                            </li>
                            <li className="">
                              <div className="left-side">
                                  <p className="date-text">Mar 05, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Order Confirmed</p>
                                <p>Your Order Confirmed.</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <Card>
                      <Card.Body>
                        <Row>
                          <Col lg={7} md={6}>
                            <div className='detail-address'>
                              <h4>Order ID: MA98543546786 </h4>
                              <p>Order Date : 25-03-2023</p>
                              <p>Order time : 10.00 am</p>
                              <p>Delivered on Tuesday,March 25th 2023</p>
                              <p className='payment-type'>Online Payment</p>
                            </div>
                          </Col>
                          <Col lg={5} md={6}>
                            <div className='detail-address float-end'>
                              <h4>Shipping Address : </h4>
                              <p>Venkatesh S</p>
                              <p>21, Sabari Street, Nesapakkam,. K.K. Nagar West, Chennai - 600 078</p>
                              <p>Email : venkatesh@gmail.com</p>
                              <p>Phone : +91 9754127856</p>
                            </div>
                          </Col>                          
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='order-cover'>
                    <div className='cart-box'>
                      <div className="cart-box">
                          <div className="cart-header">
                            <Row>
                              <Col lg={5} md={5} xs={4}>
                                <div className="cart-content">
                                  Product
                                </div>
                              </Col>
                              <Col lg={7} md={7}>
                                <Row>
                                  <Col lg={4} xs={4}>
                                    <div className="cart-content">
                                      Price
                                    </div>
                                  </Col>
                                  <Col lg={4} xs={4}>
                                    <div className="cart-content">
                                      Qty
                                    </div>
                                  </Col>
                                  <Col lg={4} xs={4}>
                                    <div className="cart-content text-end">
                                      Total
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                          <div className="cart-body">
                            {/* Start */}
                            <div className="cart-row">
                              <Row>
                                <Col lg={5} md={5} xs={3}>
                                  <div className="cart-content product">
                                    <div className="thumb">
                                      <img src="assets/images/beetomalt.png" className="img-fluid" alt="" title=""></img>
                                    </div>
                                    <div className="info">
                                      <h4>Beet o malt plus </h4>
                                      <p>250 G</p>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={7} md={7} xs={9}>
                                  <Row>
                                    <Col xs={12} className="mob-prodcut">
                                      <h4>Beet o malt plus - 250 G </h4>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                      <div className="cart-content price">
                                        <p>₹300</p>
                                      </div>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                    <div className="cart-content">
                                        <p><span className='lg-none'>Quantity</span> 2</p>
                                      </div>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                      <div className="cart-content total">
                                      <p>₹300</p> 
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>                                
                              </Row>
                            </div>                    
                          </div>
                          <div className="cart-footer">
                            <Row>
                              <Col lg={6}>&nbsp;</Col>
                              <Col lg={6}>
                                <div className='detail-grand'>
                                  <p><label>Order Subtotal </label> <span>₹600.00</span></p>
                                  <p><label>Shipping and handling </label> <span>₹100.00</span></p>
                                  <p><label>Voucher & Coupon </label> <span>₹0.00</span></p>
                                  <p><label>Tax (5%) </label> <span>₹25.00</span></p>
                                  <p className='grand-totals'><label>Grant Total </label> <span>₹725.00</span></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                      </div>  
                    </div>                    
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default OrderDetails;
