import React,{useEffect} from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {useCookies, Cookies} from 'react-cookie';

const OrderSuccess = () => { 
  const queryParams = new URLSearchParams(window.location.search)
  const invoice_id = queryParams.get('iv_Id');
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO','GUESTINFO']);


  const clearShippingInfo = (e)=>{
    const cookies1 = new Cookies();
    cookies1.remove('SHIPPINGINFO');
    cookies1.remove('COUPONINFO');
    cookies1.remove('GUESTINFO');


    // setCC("");
  }
  const getOrderSuccess = ()=>{
    return <Col lg={12}>
      <div className='orderbox'>
        <div className='success-icon'>
          <Icon icon="mdi:tick-circle" />
        </div>
        <h3>Thank You For Your Purchase! </h3>
        <p>Order ID <strong><Link to="/OrderSuccess" className='text-success'>{invoice_id}</Link></strong></p>
        <p className='mb-4'>We'll email you an order confirmation with details and tracking info. </p>
        {cookies.LOGGEDINFO!==undefined?<Link className="btn btn-lg btn-primary" to="/myorders">Go to My Orders</Link>:<Link className="btn btn-lg btn-primary"  to="/">Continue Shopping</Link>}
    </div>
  </Col>
  }

  useEffect(() => {
    clearShippingInfo()
    getOrderSuccess()
    //ClearLocalstorage()
	},[]);

  /* const ClearLocalstorage = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("doorstreet");
    localStorage.removeItem("address");
    localStorage.removeItem("pincode");
   
  } */

  return (
    <div>
        <Header />
        <section className="section header-nxt order-success">
          <Container fluid="md">
            <Row>
              {getOrderSuccess()}           
              {/* <Col lg={12}>
                <div className='orderbox'>
                    <div className='success-icon'>
                      <Icon icon="mdi:tick-circle" />
                    </div>
                    <h3>Thank You For Your Purchase! </h3>
                    <p>Order ID <strong><Link to="/OrderSuccess" className='text-success'>{invoice_id}</Link></strong></p>
                    <p className='mb-4'>We'll email you an order confirmation with details and tracking info. </p>
                    <Link className="btn btn-lg btn-primary"  to="/MyOrders">Go to My Orders</Link>
                </div>
              </Col> */}
            </Row>
          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default OrderSuccess;
