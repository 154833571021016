import React from "react";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

const SideMenu = () => {
return (
	<div className='menu-left'>
		<ul>
			<li><Link to="/myprofile"><Icon icon="mdi:user-outline" /> My Profile</Link></li>
			<li><Link to="/myorders"><Icon icon="mdi:file-document-outline" /> My Orders</Link></li>
			{/* <li><Link to="/TrackOrders"><Icon icon="mdi:map-marker-check-outline" /> Track Orders</Link></li> */}
			<li><Link to="/shippingaddress"><Icon icon="material-symbols:map-outline-sharp" /> My Addresses</Link></li>
			<li><Link to="/changepassword"><Icon icon="material-symbols:lock-open-outline" /> Change Password</Link></li>
			{/* <li><Link to="/" className="logout-btn"><Icon icon="material-symbols:power-settings-new" /> Sign Out</Link></li> */}
		</ul>
	</div>
);
};

export default SideMenu;
