import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Icon } from '@iconify/react';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SideMenu from "../Components/SideMenu";
import Modal from 'react-bootstrap/Modal';
import { postAddressSave,postAddressList,postAddressUpdate,postAddressDelete,postUserProfile,postAddressDefault,postStateSave,postCitySave } from '../services/serviceapi';
import {useCookies} from 'react-cookie';
import Toast from 'react-bootstrap/Toast';

const ShippingAddress = () => { 
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [name,setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [states,setStates] = useState([]);

  const [city, setCity] = useState("");
  const [cities,setCities] = useState([]);

  const [doorstreet, setDoorStreet] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [defaultCheck,setDefCheck] = useState(0);

  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO']);

  // const id = (Object.keys(cookies[0]).length > 0)?cookies[0].LOGGEDINFO.user_id:0;
  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

  const [editId,setEditId] = useState(id);
  const [defAddId,setDefAddId] = useState(0);
  const [defaultAddress, setDefaultAddress] = useState({});

  const defData = {name:'',mobile:'',email:'',state:'',city:'',doorstreet:'',pincode:'',address:''};
  const [formErrors,formError] = useState(defData);
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState([]);
  const [index,setIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const confirmClose = ()=> {
    setShowConfirm(false);
  }
  const handleClose = () => {
    setEdit(false);
    setShow(false);
  }
  const handleShow = () => {
    setDisabled(false);
    formError("");
    setName('');
    setMobile('');
    setEmail('');
    setState('');
    setCity('');
    setDoorStreet('');
    setAddress('');
    setPincode('');
    formError('');
    setShow(true);
  }
  const editShow = () => {
    setEdit(true);
    setShow(true);
  }
  function isValidMobileNumber(mobile) {
    // return /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(mobile);
    return /^[6-9][0-9]{9}$/i.test(mobile);

  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onChange = (e) => {
    setDisabled(false);
    const eTarget = e.target;
    // console.log(eTarget.value,"value");
    switch(eTarget.name) {
    case 'name':
      formError('');
      setName(eTarget.value)
    break;
    
    case 'mobile':
      formError('');
      setMobile(eTarget.value)
    break;

    case 'email':
      formError('');
      setEmail(eTarget.value)
    break;

    case 'state':
      formError('');
      setCity('');
      setState(eTarget.value)
      fetchCity(eTarget.value);

    break;

    case 'city':
      formError('');
      setCity(eTarget.value)
    break;

    case 'doorstreet':
      formError('');
      setDoorStreet(eTarget.value)
    break;

    case 'pincode':
      formError('');
      setPincode(eTarget.value)
    break;

    case 'address':
      formError('');
      setAddress(eTarget.value)
    break;
    
    default:
    break;
    }
  }
  const fetchCity = (state)=>{
    postCitySave(state)
    .then(response => {
      if(response.status=== 200 && response.city_list.length > 0)
      {
        setCities(response.city_list);
      }
    },)
  }
  const onSubmit = (e) => {
    setDisabled(true);

    const error = {}
    if(name ==='') error.name = 'Enter name'
    if(mobile ==='') error.mobile = 'Enter mobile number'
    else if(mobile!=="" && !isValidMobileNumber(mobile)) 
    {
      error.mobile = ' Please enter valid mobile number';
    }
    if(email ==='') error.email = 'Enter email'
    else if(email!=="" && !isValidEmail(email)) 
    {
      error.email = ' Please enter valid email';
    }
    if(state ==='') error.state = 'Select State'
    if(city ==='') error.city = 'Select City'
    if(doorstreet ==='') error.doorstreet = 'Enter door no and street name'
    if(pincode ==='') error.pincode = 'Enter pincode'
    if(address ==='') error.address = 'Enter address'
    if(Object.keys(error).length === 0)
    {
      postAddressSave(id,name,mobile,email,state,city,doorstreet,address,pincode)
      .then(response => {
        if(response.status=== 200)
        {
          setSuccessMessage(response.message);
          setName('');
          setMobile('');
          setEmail('');
          setState('');
          setCity('');
          setDoorStreet('');
          setAddress('');
          setPincode('');
          handleClose();
          toggleShowA();
          fetchAddress();

        }
        else
        {
          // respError(response.message);
          // console.log('response',response);
          // respError(response.message);
        }
      },)
    };
    formError(error)
  }
  const getUserDetails = ()=>{
    postUserProfile(id)
    .then(response => {
      if(response.status=== 200)
      {
        setMobile(response.users.mobile);
        // setFullName(response.users.name);
        // setDOB(response.users.d_o_b);
        // setEmail(response.users.email);
      }
    },)
  }
  const fetchAddress = ()=> {
    postAddressList(id)
    .then(response => {
      // if(response.status=== 200 && response.address_list.length > 0)
      // setAdd(response.address_list);
      if(response.status=== 200){
        setAdd(response.address_list);
        {response.address_list !=='' && response.address_list.length > 0 && response.address_list.map((add,index)=>{
          if(add.defualt_address !==null && add.defualt_address==1){
            setDefCheck(add.customer_address_id);
            setIndex(index);
          }
        })}
      }
    },)
  }
  
  useEffect(() => {
    getUserDetails()
    fetchAddress()
    postStateSave()
    .then(response => {
      if(response.status=== 200 && response.State_list.length > 0)
      setStates(response.State_list);
    },)
    fetchCity(state)
    // postAddressList(id)
    // .then(response => {
    //   if(response.status=== 200 && response.address_list.length > 0)
    //   setAdd(response.address_list);
    // },)
  // },[add]);
  },[]);


  const addEdit = (value) => {
    const error = {};
    formError(error);
    setName(value.customer_name);
    setMobile(value.customer_mobile);
    setEmail(value.customer_email);
    setDoorStreet(value.customer_address);
    setAddress(value.customer_address1);
    setState(value.state_id);
    fetchCity(value.state_id);
    setCity(value.city_id);
    setPincode(value.pincode);
    // handleShow();
    editShow();
  }
  const onEdit = (add_id)=> {
    postAddressUpdate(editId,add_id,name,mobile,email,doorstreet,address,state,city,pincode)
    .then(response => {
      if(response.status=== 200)
      {
        setSuccessMessage(response.message);
        // setName('');
        // setMobile('');
        // setState('');
        // setCity('');
        // setDoorStreet('');
        // setAddress('');
        // setPincode('');
        toggleShowA();
        handleClose();
        fetchAddress();

      }
    },)
  }
  const addDelete = (add_id) => {
    postAddressDelete(editId,add_id)
    .then(response => {
      if(response.status=== 200)
      {
        setSuccessMessage(response.message);
        toggleShowA();
        fetchAddress();
      }
    },)
    setShowConfirm(false);
    postAddressList(editId)
  }
  const setDefaultindex = (index,address_id) =>{
    setIndex(index);
    setDefAddId(address_id);
    //setDefCheck(1);
    postAddressDefault(editId,address_id)
    .then(response => {
      if(response.status=== 200)
      {
        setDefaultAddress(address_id)
        setDefCheck(address_id);
        // console.log('response',defaultCheck);
      }
    },)
  }
  const getAddress = () => {
    const content=[];
    {add !=='' && add.length > 0 && add.map((value,index) => (
      content.push( <Col lg={6} md={6}>
      <div className="address-box">
        <h5>{value.customer_name}</h5>
        <p>{value.customer_address}, {value.customer_address1}, {value.city} - {value.pincode} </p>                            
        <div className='address-footer'>                                
            <div className='address-edit'>
              {/* <Form.Check
                inline
                label="Default"
                name="group1"
                id="1"
                type='radio' onClick = {(e)=>setDefaultindex(index)}
              /> */}
              {/* {value.defualt_address==="1"?
              <Form.Check inline label="Default" name="group1" id="1" checked="checked" type='radio' onClick = {(e)=>setDefaultindex(index,value.customer_address_id)}/>:<Form.Check inline label="Default" name="group1" id="1"
              // checked="checked"
              type='radio' onClick = {(e)=>setDefaultindex(index,value.customer_address_id)}
            />}                                   */}
            <Form.Check inline label="Default" name="group1" id={index} value={defaultCheck} checked={defaultCheck === value.customer_address_id} type='radio' onClick = {(e)=>setDefaultindex(index,value.customer_address_id)}/>
              <Link href="#" onClick={(e) => setShowConfirm(true)} className="delete"><Icon icon="mdi:delete" /> Delete</Link>
              <Link className="edit" onClick={(e) => addEdit(add[index])} ><Icon icon="mdi:edit" /> Edit</Link>
            </div>                              
        </div>  
      </div>     
    </Col>)
    ))}
    return content;
  }
  const getState = ()=> {
    const content = [];
    states.map((value,index) => (
      content.push(<option key={index} value={value.state_id}>{value.state_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example" value={state} onChange = {onChange} name="state"> <option value = '' >Select State</option>{content}</Form.Select></>;
  }
  const getCity = ()=> {
    const content = [];
    cities.map((value,index) => (
      content.push(<option key={index} value={value.city_id}>{value.city_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example"
     onChange = {onChange} value={city}
     name="city">  <option value = '' >Select City</option>{content}</Form.Select></>;
  }
  

  return (
    <div>
        <Header />
        <Toast show={showA} onClose={toggleShowA} className='toast-success' delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{successMessage}</strong>
          </Toast.Header>
        </Toast>
        <section className="section header-nxt my-orders">
          <Container fluid="md">
            <Row>
              <Col lg={3} md={3}>
                <SideMenu />                
              </Col>
              <Col lg={9} md={9}>
                <div className='account-right myaddress'>
                  <h3 className='main-heading2'>My Addresses</h3>
                  {(add==='' || add.length === 0)?<div className="cart-empty empty-alert">
                    <img src="/assets/images/address-empty.png" className="img-fluid" alt="" title=""></img>
                    <h5><br></br>Address is Empty</h5>
                    <p>You still not add any address</p>
                  </div>:''}
                  <Row>            
                    <Col lg={9}>
                      <Row>
                        {getAddress()}
                      </Row>   
                    </Col>
                    <Col lg={3} md={6}>
                      <div className="add-address">
                        <Link onClick={handleShow}>
                          <Icon icon="ph:plus-circle" /> 
                          <p>Add Address</p>
                        </Link>
                      </div>             
                    </Col>
                  </Row>
                </div>           
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

        {/* Add New Address Modal */}
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Name <span className="req">*</span></Form.Label>
                  <Form.Control type="text" maxLength="30" placeholder="Enter Name" onChange = {onChange} name="name" value={name} />
                  {/* <Form.Text className="error text-danger">Invalid</Form.Text>                      */}
                  {formErrors.name!=='' && formErrors.name!==undefined && (<Form.Text className='error text-danger'>{formErrors.name}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Mobile No. <span className="req">*</span></Form.Label>
                  <Form.Control type="text" maxLength ="10" autoComplete='off' name="mobile" value={mobile} placeholder="Enter Mobile No." onChange = {onChange} />
                  {formErrors.mobile!=='' && formErrors.mobile!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobile}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Email <span className="req">*</span></Form.Label>
                  <Form.Control type="email" autoComplete='off' name="email" value={email} placeholder="Enter Email" onChange = {onChange} />
                  {formErrors.email!=='' && formErrors.email!==undefined && (<Form.Text className='error text-danger'>{formErrors.email}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>State <span className="req">*</span></Form.Label>
                  {getState()}

                  {/* <Form.Select aria-label="Default select example" onChange = {onChange} name="state" value={state}>
                    <option>Select State</option>
                    <option value="1">Tamilnadu</option>
                    <option value="2">Kerala</option>
                    <option value="3">karnataka</option>
                  </Form.Select> */}
                  {formErrors.state!=='' && formErrors.state!==undefined && (<Form.Text className='error text-danger'>{formErrors.state}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>City <span className="req">*</span></Form.Label>
                  {getCity()}
                  {/* <Form.Control type="text" name="city" value={city} placeholder="Enter City" onChange = {onChange} /> */}
                  {formErrors.city!=='' && formErrors.city!==undefined && (<Form.Text className='error text-danger'>{formErrors.city}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Door No. / Street <span className="req">*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Street" onChange = {onChange} name="doorstreet" value={doorstreet} />
                  {formErrors.doorstreet!=='' && formErrors.doorstreet!==undefined && (<Form.Text className='error text-danger'>{formErrors.doorstreet}</Form.Text>)}
                </Form.Group>
              </Col>                            
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Pincode <span className="req">*</span></Form.Label>
                  <Form.Control type="text" maxLength="6" name="pincode" value={pincode} placeholder="Enter Pincode" onChange = {onChange} />
                  {formErrors.pincode!=='' && formErrors.pincode!==undefined && (<Form.Text className='error text-danger'>{formErrors.pincode}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Address <span className="req">*</span></Form.Label>
                  <Form.Control as="textarea" rows={3} name="address" value={address} placeholder="Enter Address" onChange = {onChange} />
                  {formErrors.address!=='' && formErrors.address!==undefined && (<Form.Text className='error text-danger'>{formErrors.address}</Form.Text>)}
                </Form.Group>
              </Col>
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Close
            </Button>
            {!isEdit && (<Button variant="primary" disabled={isDisabled} onClick={onSubmit}>Save Address</Button>)}
            {isEdit && (<Button variant="primary" onClick={(e) =>onEdit(add[index].customer_address_id)}>Update Address</Button>)}
            {/* <Button variant="primary" onClick={onSubmit}>
              Save Address
            </Button> */}
          </Modal.Footer>
        </Modal>

        {/* Delete confirmation Modal */}
        <Modal show={showConfirm} onHide={confirmClose} size="md" className='modal-alert-option'>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              Are you sure want to delete?
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={(e) =>addDelete(add[index].customer_address_id)}>
              Ok
            </Button>
            <Button variant="light" onClick={confirmClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

    </div>
  );
};

export default ShippingAddress;
