import React,{useState,useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SideMenu from "../Components/SideMenu";
import { getOrderDetail } from '../services/serviceapi';
import {useCookies,Cookies} from 'react-cookie';
import moment from 'moment';


const OrderDetails = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const invoice_id = queryParams.get('Iv_id');
  const [cartlist, setCartList] = useState([]);
  const [orderlist, setOrderList] = useState({});
  const [cc,setCC] = useState("");
  const [coupon_code,setCouponCode] = useState('');
  const [coupon_message,setCouponMessage] = useState('');
  const [coupon_amount,setCouponAmount] = useState(0);
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO',"COUPONINFO"]);
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;


  const fetchOrderDetails = ()=>{
    getOrderDetail(invoice_id)
    .then(response => {
      if(response.order_det.status && response.cart_det.status === 200)
      {
        // console.log(response,'details');
        setOrderList(response.order_det.order_list)
        setCartList(response.cart_det.order_list)
        // setCC(response.)
      }
    },)
  }
  const getCartList = ()=>{
    const content=[];
    if(cartlist.length > 0){
      cartlist.map((value,index) => (
      content.push(<div className="cart-row">
      <Row>
        <Col lg={5} md={6} xs={3}>
          <div className="cart-content product">
            <div className="thumb">
              <img src={value.product_image} className="img-fluid" alt="" title=""></img>
            </div>
            <div className="info">
              <h4>{value.product_name} </h4>
              <p>{value.variant_name} {value.unit_name}</p>
            </div>
          </div>
        </Col>

        <Col lg={7} md={6} xs={9}>
          <Row>
            <Col xs={12} className="mob-prodcut">
              <h4>Beet o malt plus - 250 G </h4>
            </Col>
            <Col lg={4} md={4} xs={12}>
              <div className="cart-content price">
                <p>₹{value.product_price}</p>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12}>
            <div className="cart-content">
                <p><span className='lg-none'>Quantity</span>{cartlist[index].quantity}</p>
              </div>
            </Col>
            <Col lg={4} md={4} xs={12}>
              <div className="cart-content total">
              <p>₹{cartlist[index].quantity * cartlist[index].product_price}</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>)
    ))
    }
    return content;
  }
  const getSubTotal = ()=>{
    let subTotal = 0;
    if (cartlist.length > 0)
    {
      cartlist.map((value,index) => (
        subTotal = subTotal + cartlist[index].product_price * cartlist[index].quantity
      ))
      return subTotal;
    }
  }
  

  useEffect(() => {
    fetchOrderDetails()
    
  },[]);

  return (
    <div>
        <Header />
        <section className="section header-nxt my-orders">
          <Container fluid="md">
            <Row>            
              <Col lg={3} md={3}>
                <SideMenu />                
              </Col>
              <Col lg={9} md={9}>
                <div className='account-right'>
                  <h3 className='main-heading2'>Orders Details</h3>
                  <div className='order-details-card mb-4'>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col lg={5} md={6}>
                            {/* <div className='detail-address'>
                              <h4>Shipping Address : </h4>
                              <p>{orderlist.customer_name}</p>
                              <p>{orderlist.customer_address}, {orderlist.customer_address1}, {orderlist.city_name} - {orderlist.pincode}</p>
                              <p>Email : {orderlist.customer_email}</p>
                              <p>Phone : +91 {orderlist.customer_mobile}</p>
                            </div> */}
                          </Col>
                          <Col lg={7} md={6}>
                          <div className='detail-address text-end'>
                              <h4>Order ID: {orderlist.invoice_id} </h4>
                              <p>Order Date : {moment(orderlist.order_date).format('DD-MM-YYYY')}</p>
                              {/* <p>Order Time : 10.00 am</p> */}
                              {/* <p>Delivered on Tuesday,March 25th 2023</p> */}
                              <p className='payment-type'>Online Payment</p>
                            </div>
                          </Col>
                        </Row>
                        <hr/>
                        <Row>
                          <Col lg={6} md={6}>
                            <div className='detail-address'>
                              <h4>Billing Address : </h4>
                              <p>{orderlist.bil_customer_name}</p>
                              <p>{orderlist.bil_customer_address}, {orderlist.bil_customer_address1}, {orderlist.bil_city_name} - {orderlist.bil_pincode}</p>
                              <p>Email : {orderlist.bil_customer_email}</p>
                              <p>Phone : +91 {orderlist.bil_customer_mobile}</p>
                            </div>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className='detail-address'>
                              <h4>Customer Address : </h4>
                              <p>{orderlist.customer_name}</p>
                              <p>{orderlist.customer_address}, {orderlist.customer_address1}, {orderlist.city_name} - {orderlist.pincode}</p>
                              <p>Email : {orderlist.customer_email}</p>
                              <p>Phone : +91 {orderlist.customer_mobile}</p>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='order-cover'>
                    <div className='cart-box'>
                      <div className="cart-box">
                          <div className="cart-header">
                            <Row>
                              <Col lg={5} md={6} xs={6}>
                                <div className="cart-content">
                                  Product
                                </div>
                              </Col>
                              <Col lg={7} md={6}>
                                <Row>
                                  <Col lg={4} md={4} xs={12}>
                                    <div className="cart-content">
                                      Price
                                    </div>
                                  </Col>
                                  <Col lg={4} md={4} xs={12}>
                                    <div className="cart-content">
                                      Qty
                                    </div>
                                  </Col>
                                  <Col lg={4} md={4} xs={12}>
                                    <div className="cart-content text-end">
                                      Total
                                    </div>
                                  </Col>
                                </Row>
                              </Col>                              
                            </Row>
                          </div>
                          <div className="cart-body">
                          {getCartList()}
                            {/* <div className="cart-row">
                              <Row>
                                <Col lg={5} md={6} xs={3}>
                                  <div className="cart-content product">
                                    <div className="thumb">
                                      <img src="assets/images/beetomalt.png" className="img-fluid" alt="" title=""></img>
                                    </div>
                                    <div className="info">
                                      <h4>Beet o malt plus </h4>
                                      <p>250 G</p>
                                    </div>
                                  </div>
                                </Col>

                                <Col lg={7} md={6} xs={9}>
                                  <Row>
                                    <Col xs={12} className="mob-prodcut">
                                      <h4>Beet o malt plus - 250 G </h4>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                      <div className="cart-content price">
                                        <p>₹300</p>
                                      </div>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                    <div className="cart-content">
                                        <p><span className='lg-none'>Quantity</span>2</p>
                                      </div>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                      <div className="cart-content total">
                                      <p>₹600</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>    */}

                            {/* <div className="cart-row">
                              <Row>
                                <Col lg={5} md={6} xs={3}>
                                  <div className="cart-content product">
                                    <div className="thumb">
                                      <img src="assets/images/fruitomalt.png" className="img-fluid" alt="" title=""></img>
                                    </div>
                                    <div className="info">
                                      <h4>Fruit O Malt </h4>
                                      <p>250 G</p>
                                    </div>
                                  </div>
                                </Col>

                                <Col lg={7} md={6} xs={9}>
                                  <Row>
                                    <Col xs={12} className="mob-prodcut">
                                      <h4>Fruit O Malt - 250 G </h4>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                      <div className="cart-content price">
                                        <p>₹300</p>
                                      </div>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                    <div className="cart-content">
                                        <p><span className='lg-none'>Quantity</span>2</p>
                                      </div>
                                    </Col>
                                    <Col lg={4} md={4} xs={12}>
                                      <div className="cart-content total">
                                      <p>₹600</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>    */}

                          </div>
                          <div className="cart-footer">
                            <Row>
                              <Col lg={6}>&nbsp;</Col>
                              <Col lg={6}>
                                <div className='detail-grand'>
                                  <p><label>Order Subtotal </label> <span>₹{getSubTotal()}.00</span></p>
                                  <p><label>Shipping and handling </label> <span>₹{orderlist.delivery_charge}.00</span></p>
                                  <p><label> Coupon({orderlist.coupon_code}) </label> <span>₹{orderlist.discount_amount}.00</span></p>
                                  {/* <p><label>Tax (5%) </label> <span>₹25.00</span></p> */}
                                  <p className='grand-totals'><label>Grant Total </label> <span>₹{orderlist.total_order_price}.00</span></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                      </div>  
                    </div>
                    {/* <div className='track-cover mt-4'>
                      <h3 className='main-heading2'>Order Tracking</h3>
                      <div className='order-track'>
                        <div className="timeline">
                          <ul>
                            <li className="active">
                              <div className="left-side">
                                  <p className="date-text">Mar 20, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Delivered</p>
                                <p>Your product is delivered.</p>
                              </div>
                            </li>
                            <li className="">
                              <div className="left-side">
                                  <p className="date-text">Mar 18, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Out for Delivery</p>
                                <p>Your product is Out for Delivery</p>
                              </div>
                            </li>
                            <li className="">
                              <div className="left-side">
                                  <p className="date-text">Mar 12, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Ready to Despatch</p>
                                <p>Your product is Ready to Despatch.</p>
                              </div>
                            </li>
                            <li className="">
                              <div className="left-side">
                                  <p className="date-text">Mar 05, 2023</p>
                                  <p>05:01 PM</p>
                              </div>
                              <div className="content">
                                <p className="date-text">Order Confirmed</p>
                                <p>Your Order Confirmed.</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default OrderDetails;
