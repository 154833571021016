import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const SkeletonLoader = () => {
  return (
    <div>
        <Header />

        <section className="section">
          <Container fluid="md">
            <Row>            
              <Col lg={12}>
                 <h3>Style 1</h3>
                 <div className="skeleton banner-loader"></div>                  
              </Col>
            </Row>

            <hr/>
            <h3>Products Show One</h3>
            <Row>
              <Col lg={7}>
                <div className="product-skeleton">
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w60 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                </div>  
              </Col>
              <Col lg={5}>
                <div className="product-image-skl skeleton">
                  test
                </div>
              </Col>
            </Row>

            <h3>Features</h3>
            <Row className="features-skeleton">
              <Col lg={3} xs={12}>
                <Row>
                  <Col lg={4} xs={4}>
                    <div className="product-skeleton skeleton thumb"></div>
                  </Col>
                  <Col lg={8} xs={8}>
                    <div className="product-skeleton content">
                      <div className="headingskl w90 skeleton"></div>
                      <div className="descskl w100 skeleton"></div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} xs={12}>
                <Row>
                  <Col lg={4} xs={4}>
                    <div className="product-skeleton skeleton thumb"></div>
                  </Col>
                  <Col lg={8} xs={8}>
                    <div className="product-skeleton content">
                      <div className="headingskl w90 skeleton"></div>
                      <div className="descskl w100 skeleton"></div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} xs={12}>
                <Row>
                  <Col lg={4} xs={4}>
                    <div className="product-skeleton skeleton thumb"></div>
                  </Col>
                  <Col lg={8} xs={8}>
                    <div className="product-skeleton content">
                      <div className="headingskl w90 skeleton"></div>
                      <div className="descskl w100 skeleton"></div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} xs={12}>
                <Row>
                  <Col lg={4} xs={4}>
                    <div className="product-skeleton skeleton thumb"></div>
                  </Col>
                  <Col lg={8} xs={8}>
                    <div className="product-skeleton content">
                      <div className="headingskl w90 skeleton"></div>
                      <div className="descskl w100 skeleton"></div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <h3>Products Show 3</h3>
            <Row>
              <Col lg={4}>
                <div className="product-skeleton center">
                  <div className="imageskl skeleton"></div>
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                  </Row>
                </div>  
              </Col>
              <Col lg={4}>
                <div className="product-skeleton center">
                  <div className="imageskl skeleton"></div>
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                  </Row>
                </div>  
              </Col>
              <Col lg={4}>
                <div className="product-skeleton center">
                  <div className="imageskl skeleton"></div>
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                  </Row>
                </div>  
              </Col>
            </Row>

            <h3>Testimonials</h3>
            <Row>
              <Col lg={2}>
                <div className="product-skeleton skeleton">
                  <div className="thumb-col3"></div>
                </div>
              </Col>
              <Col lg={10}>                 
                <div className="product-skeleton">
                  <div className="descskl skeleton"></div>
                  <div className="descskl skeleton"></div>
                  <div className="descskl skeleton"></div>
                  <div className="descskl skeleton"></div>
                  <div className="descskl skeleton"></div>
                  <div className="descskl w70 skeleton"></div> 
                </div> 
              </Col>
            </Row>

            <h3>Frequently Asked Questions</h3>
            <Row>            
              <Col lg={5}>                 
                <div className="product-skeleton">
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="thumb-col3 w60 skeleton"></div>
                </div>                   
              </Col>
              <Col lg={7}>           
                <div className="product-skeleton">
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                </div>               
              </Col>
            </Row>

            <h3>Have You Drink</h3>
            <Row>
              <Col lg={6}>       
                <div className="product-skeleton">
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                </div>                   
              </Col>
              <Col lg={6}>                 
                <div className="product-skeleton">
                  <div className="thumb-col3 skeleton"></div>
                </div>               
              </Col>
            </Row>

            <h3>CMS</h3>
            <Row>
              <Col lg={12}>
                <div className="product-skeleton skeleton-content">
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w100 skeleton"></div>
                  <div className="descskl w70 skeleton"></div>
                </div>
              </Col>
            </Row>

          </Container>
        </section>
        <Footer />
    </div>
  );
};

export default SkeletonLoader;
