import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Promotion = (params) => {
	return (				
    <section className="section promo">
      <img src=
      // "../assets/images/beet-o-vitaa-plus-slider.jpg"
      {(params.data !==undefined)?params.data.promotion_image:'../assets/images/beet-o-vitaa-plus-slider.jpg'}
       alt="" title="" className="w-100"/>
    {/* <Container fluid="md">
      <Row>            
          <Col lg={12}>
            
          </Col>
      </Row>
    </Container> */}
  </section>
	);
};

export default Promotion;
