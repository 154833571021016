import React, { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useCookies,Cookies} from 'react-cookie';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SideMenu from "../Components/SideMenu";
import Toast from 'react-bootstrap/Toast';
import { Link,useNavigate } from 'react-router-dom';
import { postUserProfile,postUpdateProfile } from "../services/serviceapi";


const MyProfile = () => { 
  const [fullname,setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [responseSuccess,respSuccess] = useState('');
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  // const [username,setName] = useState("");
  const defData = {fullname:'',mobile:'',dob:'',email:''};
  const [formErrors,formError] = useState(defData);
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO']);
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  
  const onChange = (e) => {
    const eTarget = e.target;
    // console.log(eTarget.value,"value")
    switch(eTarget.name) {
    case 'fullname':
      setFullName(eTarget.value)
    break;

    case 'dob':
      setDOB(eTarget.value)
    break;

    case 'email':
      setEmail(eTarget.value)
    break;

    default:
    break;
    }
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = (e) => {
    // const re=/\S+@\S+\.\S+/;
    const error = {}
    if(fullname ==='') error.fullname = 'Enter Full Name'
    if(email ==='') error.email = 'Enter email'
    else if(email!=="" && !isValidEmail(email)) 
    {
      error.email = ' Please enter valid email';
    }
    formError(error)
    if(Object.keys(error).length === 0)
    {
      postUpdateProfile(id,fullname,mobile,email,dob)
      .then(response => {
        // console.log(response,'response');
        if(response.status=== 200)
        {
          toggleShowA()
          respSuccess(response.message);
        }
        else
        {
          // respError(response.message);
          // console.log('response',response);
          // respError(response.message);
        }
      },)
    };
  }
  const getUserDetails = ()=>{
    postUserProfile(id)
    .then(response => {
      if(response.status=== 200)
      {
        setMobile(response.users.mobile);
        setFullName(response.users.name);
        setDOB(response.users.d_o_b);
        setEmail(response.users.email);
      }
    },)
  }
  useEffect(() => {
    // console.log(id,'prof id');
    // console.log(!isNaN(id),'IoP');
    // console.log(id.length,'length');
    if (id.length >= 5 || id.length===undefined)
    {
      let path = `/login`;
      navigate(path);
    }
    else
    {
      let path = `/myprofile`;
      navigate(path);
      getUserDetails()
    }
    // if (!isNaN(id)===true)
    // {
    //   let path = `/MyProfile`;
    //   navigate(path);
    //   getUserDetails()
    // }
    // else
    // {
    //   let path = `/login`;
    //   navigate(path);
    // }
    // getUserDetails()
  },[]);

  return (
    <div>
        <Header />
        <Toast show={showA} onClose={toggleShowA} className='toast-success' delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{responseSuccess}</strong>
          </Toast.Header>
        </Toast>
        <section className="section header-nxt my-orders">
          <Container fluid="md">
            <Row>            
              <Col lg={3} md={3}>
                <SideMenu />                
              </Col>
              <Col lg={9} md={9}>
                <div className='account-right'>
                  <h3 className='main-heading2'>My Profile</h3>
                  <div className='form-style1'>
                  <Form>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>Full Name <span className="req">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Full Name" onChange = {onChange} name="fullname" defaultValue={fullname} />
                            {formErrors.fullname!=='' && formErrors.fullname!==undefined && (<Form.Text className='error text-danger'>{formErrors.fullname}</Form.Text>)}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>Mobile Number <span className="req">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Phone Number" onChange = {onChange} name="mobile" value={mobile} />
                            {formErrors.mobile!=='' && formErrors.mobile!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobile}</Form.Text>)}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>Email ID <span className="req">*</span></Form.Label>
                            <Form.Control type="email" placeholder="Enter Email ID " onChange = {onChange} name="email" defaultValue={email} />
                            {formErrors.email!=='' && formErrors.email!==undefined && (<Form.Text className='error text-danger'>{formErrors.email}</Form.Text>)}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>Date of Birth <span className="req"></span></Form.Label>
                            <Form.Control type="date" max={moment().format("dd/mm/YYYY")} placeholder="Enter Date of Birth" onChange = {onChange} name="dob" defaultValue={dob} />
                            {/* {formErrors.dob!=='' && formErrors.dob!==undefined && (<Form.Text className='error text-danger'>{formErrors.dob}</Form.Text>)} */}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <div className='button-group mt-3 text-end'>
                            <Button variant="primary" className='me-0' onClick={onSubmit}>Update </Button >
                          </div>
                          {/* {responseError} */}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default MyProfile;
