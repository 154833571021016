import React,{useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Home from './Pages/Home';
import Login from './Pages/Login';
import Register from './Pages/Register';
import ForgotPassword from './Pages/ForgotPassword';
import ProductDetail from './Pages/ProductDetail';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import ConfirmOrder from './Pages/ConfirmOrder';
import OrderSuccess from './Pages/OrderSuccess';
import MyOrders from './Pages/MyOrders';
import MyProfile from './Pages/MyProfile';
import ChangePassword from './Pages/ChangePassword';
import ShippingAddress from './Pages/ShippingAddress';
import OrderDetails from './Pages/OrderDetails';
import TrackOrders from './Pages/TrackOrders';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsCondition from './Pages/TermsCondition';
import ReturnPolicy from './Pages/ReturnPolicy';
import ShippingPolicy from './Pages/ShippingPolicy';
import ProductList from './Pages/ProductList';
import Otp from './Pages/Otp';
import SkeletonLoader from './Pages/SkeletonLoader';
import ScrollToTop from "react-scroll-to-top";
import OrderFail from './Pages/OrderFail';
import ForgotOtp from './Pages/ForgotOtp';
import ConfirmPassword from "./Pages/ConfirmPassword";
import { ReactComponent as MySVG } from "./scroll-top.svg";

function App() {  

  useEffect(() => {
    AOS.init({disable: 'mobile'});

  }, []);

  window.addEventListener('scroll', function() {
    var body = document.querySelector('body');
    var scrollPosition = window.scrollY;
    if (scrollPosition > 0) {
      body.classList.add('scrolled');
    } else {
      body.classList.remove('scrolled');
    }
  });

  return (
    <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/product-detail" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/confirmorder" element={<ConfirmOrder />} />
            <Route path="/ordersuccess" element={<OrderSuccess />} />
            <Route path="/myorders" element={<MyOrders />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/confirmpassword" element={<ConfirmPassword />} />
            <Route path="/shippingaddress" element={<ShippingAddress />} />
            <Route path="/orderdetails" element={<OrderDetails />} />
            <Route path="/trackorders" element={<TrackOrders />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termscondition" element={<TermsCondition />} />
            <Route path="/returnpolicy" element={<ReturnPolicy />} />
            <Route path="/shippingpolicy" element={<ShippingPolicy />} />
            <Route path="/productlist" element={<ProductList />} />  
            <Route path="/otp" element={<Otp />} />
            <Route path="/forgot-otp" element={<ForgotOtp />} />   
            <Route path="/orderfail" element={<OrderFail />} />
            <Route path="/skeletonloader" element={<SkeletonLoader />} />       

            <Route path="*" element={<Home />} />
          </Routes>

          <ScrollToTop smooth component={<MySVG />} />
      </>
  );
}

export default App;
