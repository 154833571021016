import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import {useCookies,Cookies} from 'react-cookie';
import Toast from 'react-bootstrap/Toast';
import { v4 as uuid } from 'uuid';
import validator from 'validator';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { postAddressList,postAddressSave,postAddressUpdate,postAddressDelete,postStateSave,postCitySave,postAddressDefault,postCartList,postBillingAddressDetails,postShippingAddressAdd,postShippingAddressUpdate,postCouponCode,postSaveGuestUser,postGuestOTPValidate,postResendOTP } from '../services/serviceapi';

const Checkout = () => {
  const navigate = useNavigate();
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [name,setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const [state, setState] = useState(0);
  const [states,setStates] = useState([]);
  // const [state_id,setStateId] = useState();
  const [city, setCity] = useState(0);
  const [cities,setCities] = useState([]);
  const [doorstreet, setDoorStreet] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");


  const [shippingname,setShippingName] = useState("");
  const [shippingmobile, setShippingMobile] = useState("");
  const [shippingemail, setShippingEmail] = useState("");

  const [shippingstate, setShippingState] = useState(0);
  // const [billingstates,setBillingStates] = useState([]);
   const [defaultGuest,setDefaultGuestUser] = useState('');
  const [shippingcity, setShippingCity] = useState(0);
  // const [billingcities,setBillingCities] = useState([]);
  const [shippingdoorstreet, setShippingDoorStreet] = useState("");
  const [shippingpincode, setShippingPincode] = useState("");
  const [shippingaddress, setShippingAddress] = useState("");
  const [shipCost,setShipCost] = useState(0);
  const [invalidOTP,setInvalidOTP] = useState("");
  const [cc,setCC] = useState("");
  const [coupon_code,setCouponCode] = useState('');
  const [coupon_message,setCouponMessage] = useState('');
  const [coupon_amount,setCouponAmount] = useState(0);
  const [invalidcc,setInvalidCC] = useState("");
  const [ccApplied,setCCApplied] = useState('');
  const [ccAmount,setCCAmount] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO',"COUPONINFO","GUESTINFO","SHIPPINGINFO"]);

  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;
  const [editId,setEditId] = useState(id);
  const [deleteId,setDeleteId] = useState(0);
  const [updateId,setUpdateId] = useState(0);
  const [defAddId,setDefAddId] = useState(0);
  const [shipId,setShipId] = useState(0);
  const [defaultCheck,setDefCheck] = useState(0);
  const [billingAddress, setBillingAddress] = useState(false);
  const [responseCart,respSuccess] = useState([]);
  const defData = {name:'',mobile:'',email:'',state:'',city:'',doorstreet:'',pincode:'',address:'',cc:''};
  const guestData = {guestname:'',guestphone:'',guestotp:''};
  const [guestname,setGuestName] = useState("");
  const [guestphone,setGuestPhone] = useState("");
  const [guestotp,setGuestOtp] = useState("");
  const [guestformerrors, guestformError] = useState(guestData);
  const [guest, setGuest] = useState(0);
  const [defaultguest, setDefaultGuest] = useState(false);
  const [guestshow, setGuestShow] = useState(false);
  const handleRegister = () => setGuestShow(true);
  const [formErrors,formError] = useState(defData);
  const [add, setAdd] = useState([]);
  const [publicIndex,setIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDefaultCheck,setDefaultCheck] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [orpOTP,setOrgOtp] = useState(0);
  const [iscontinue,setContinue] = useState();
  const [isguest,setGuestcontinue] = useState();
  const [issubmit,setAddresssubmit] = useState();


  const defaultCheckClose = ()=> {
    setDefaultCheck(false);
  }
  const fetchCartdata = ()=> {
    /* const data = window.location.href.split('?')
    var x = parseInt(data[1]);
    setGuest(x)
  console.log('vdata',cookies.GUESTINFO) */

    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if (cookieData.sessId === undefined || cookieData.sessId ==='')
      {
        const unique_id = uuid();
        //console.log(unique_id,'uuid');
        setCookie('SESSIONINFO', JSON.stringify(unique_id));
        cookieData.sessId = unique_id;
        
      }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartList(id)
        .then(response => {
          if(response.status=== 200)
          {
            respSuccess(response.cart_list);
          }
        },)
      }
    }
    else
    {
      postCartList(editId)
      .then(response => {
        if(response.status=== 200)
        {
          respSuccess(response.cart_list);
        }
      },)
    }
}
  const getSubTotal = ()=>{
    let subTotal = 0;
    if (responseCart.length > 0)
    {
      responseCart.map((value,index) => (
        subTotal = subTotal + responseCart[index].product_price * responseCart[index].quantity
      ))
      return subTotal;
    }
  }
  const getAmountPayable = ()=>{
    let amount = 0;
    // let shipping = 100;
    let c_amount = (coupon_amount!==0)?coupon_amount:ccAmount
    amount = Math.round(getSubTotal()  - c_amount);
    return amount;
  }

  const confirmClose = ()=> {
    setShowConfirm(false);
  }
  const handleClose = () => {
    setEdit(false);
    setShow(false);
  }
  const handleShow = () => {
    setDisabled(false);
    formError("");
    setShippingName('');
    setShippingMobile('');
    setShippingEmail('');
    setShippingState('');
    setShippingCity('');
    setShippingDoorStreet('');
    setShippingAddress('');
    setShippingPincode('');
    setShow(true);
  }
  const editShow = () => {
    // formError("");
    setEdit(true);
    setShow(true);
  }
  const onChangeShipping = (e) => {
    setDisabled(false);
    formError('');
    const eTarget = e.target;
    switch(eTarget.name) {
    case 'shippingname':
      setShippingName(eTarget.value)
    break;
    
    case 'shippingmobile':
      setShippingMobile(eTarget.value)
    break;

    case 'shippingemail':
      setShippingEmail(eTarget.value)
    break;

    case 'shippingdoorstreet':
      setShippingDoorStreet(eTarget.value)
    break;

    case 'shippingpincode':
      setShippingPincode(eTarget.value)
    break;

    case 'shippingaddress':
      setShippingAddress(eTarget.value)
    break;
    
    default:
    break;
    }
  }
  const onChange = (e) => {
    setBillingAddress(false);
    const eTarget = e.target;
    switch(eTarget.name) {
    case 'name':
      setName(eTarget.value)
    break;
    
    case 'mobile':
      setMobile(eTarget.value)
    break;

    case 'email':
      setEmail(eTarget.value)
    break;

    case 'doorstreet':
      setDoorStreet(eTarget.value)
    break;

    case 'pincode':
      setPincode(eTarget.value)
    break;

    case 'address':
      setAddress(eTarget.value)
    break;
    
    default:
    break;
    }
  }
  const onChangeGuest = (e) => {
    const eTarget = e.target;
    switch(eTarget.name) {
    case 'guestname':
      setGuestName(eTarget.value)
    break;
    
    case 'guestphone':
      setGuestPhone(eTarget.value)
    break;

    default:
    break;
    }
  }
  const onChangeShippingState = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setDisabled(false);
      setShippingCity('');
      setShippingState(eTarget.value);
      fetchCity(eTarget.value);
    }else{
      setShippingCity('');
      setShippingState(eTarget.value);
      fetchCity(0);
    }
  }
  const onChangeShippingCity = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setDisabled(false);
      // console.log(eTarget.value,'state');
      setShippingCity(eTarget.value);
    }else{
      // console.log(eTarget.value,'state1');
      setShippingCity('');
    }
    // setShippingCity(eTarget.value);
  }
  const onChangeState = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setBillingAddress(false);
      setCity("");
      setState(eTarget.value);
      fetchCity(eTarget.value);
    }else{
      setState(eTarget.value);
      fetchCity(0);
    }
    // setState(eTarget.value);
    // fetchCity(eTarget.value);
  }
  const onChangeCity = (e)=>{
    const eTarget = e.target;
    if(eTarget.value !==''){
      setBillingAddress(false);
      // console.log(eTarget.value,'state');
      setCity(eTarget.value);
    }else{
      // console.log(eTarget.value,'state1');
      setCity('');
    }
    // setCity(eTarget.value);
  }
  const onChangeCC = (e)=>{
    const eTarget = e.target;
    setCC(eTarget.value);
  }
  const fetchCity = (state)=>{
    postCitySave(state)
    .then(response => {
      if(response.status=== 200 && response.city_list.length > 0)
      {
        setCities(response.city_list);
      }
      else{
        setCities([]);
      }
    },)
  }

  function isValidMobileNumber(mobile) {
    // return /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(mobile);
    return /^[6-9][0-9]{9}$/i.test(mobile);
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidPincode(pincode){
    return /^[1-9][0-9]{5}$/i.test(pincode)
  }
  const onSubmit = (e) => {
    setDisabled(true);
    const error = {}
    if(shippingname ==='') error.shippingname = 'Enter name'
    if(shippingmobile ==='') error.shippingmobile = 'Enter mobile number'
    else if(shippingmobile!=="" && !isValidMobileNumber(shippingmobile))
    {
      error.shippingmobile = ' Please enter valid mobile number';
    }
    if(shippingemail ==='') error.shippingemail = 'Enter email'
    else if(shippingemail!=="" && !isValidEmail(shippingemail))
    {
      error.shippingemail = ' Please enter valid email';
    }
    if(shippingstate ==='') error.shippingstate = 'Select State'
    if(shippingcity ==='') error.shippingcity = 'Select City'
    if(shippingdoorstreet ==='') error.shippingdoorstreet = 'Enter door no and street name'
    if(shippingpincode ==='') error.shippingpincode = 'Enter pincode'
    else if(shippingpincode !=='' && !isValidPincode(shippingpincode))
    {
     error.shippingpincode = 'Enter valid pincode'
    }
    if(shippingaddress ==='') error.shippingaddress = 'Enter address'
    if(Object.keys(error).length === 0)
    {
      postAddressSave(editId,shippingname,shippingmobile,shippingemail,shippingstate,shippingcity,shippingdoorstreet,shippingaddress,shippingpincode)
      .then(response => {
        if(response.status=== 200)
        {
          setSuccessMessage(response.message);
          setShipCost(response.shipping_cost);
          setShippingName('');
          setShippingMobile('');
          setShippingEmail('');
          setShippingState('');
          setShippingCity('');
          setShippingDoorStreet('');
          setShippingAddress('');
          setShippingPincode('');
          handleClose();
          toggleShowA()
          fetchAddress();
        }
        else
        {
          // respError(response.message);
          // console.log('response',response);
          // respError(response.message);
        }
      },)
    };
    formError(error)
  }
  const onShippingSubmit = ()=>{
    const error = {}
    if(name ==='') error.name = 'Enter name'
    if(mobile ==='') error.mobile = 'Enter mobile number'
    else if(mobile!=="" && !isValidMobileNumber(mobile)) 
    {
      error.mobile = ' Please enter valid mobile number';
    }
    if(email ==='') error.email = 'Enter email'
    else if(email!=="" && !isValidEmail(email)) 
    {
      error.email = ' Please enter valid email';
    }
    if(state ==='') error.state = 'Select State'
    if(city ==='') error.city = 'Select City'
    if(doorstreet ==='') error.doorstreet = 'Enter door no and street name'
    if(pincode ==='') error.pincode = 'Enter pincode'
    else if(pincode !=='' && !isValidPincode(pincode))
    {
     error.pincode = 'Enter valid pincode'
    }
    if(address ==='') error.address = 'Enter address'
    if(Object.keys(error).length === 0)
    {
      // console.log('shipid',shipId);
      /* if (shipId !== 0)
      {
        postShippingAddressUpdate(editId,shipId,name,mobile,state,city,doorstreet,address,pincode)
        .then(response => {
          if(response.status=== 200)
          {
            // console.log(shipId,"ship");
            setSuccessMessage(response.message);
            setShipId(response.shiping_id);

            const cookies1 = new Cookies();
            if(cookies.SHIPPINGINFO !==undefined){
              cookies1.remove('SHIPPINGINFO');
            }
            const shippingData = {"name":name,"mobile":mobile,"state":state,"city":city,"doorstreet":doorstreet,"address":address,"pincode":pincode};
            setCookie('SHIPPINGINFO', JSON.stringify({shipping_data:shippingData}));
            // setName('');
            // setMobile('');
            // setState('');
            // setCity('');
            // setDoorStreet('');
            // setAddress('');
            // setPincode('');
            // handleClose();
            toggleShowA()
          }
        },)
      } */
      /* else
      { */
        let billingaddress_check  = billingAddress === false?0:1;
        postShippingAddressAdd(editId,shipId,name,mobile,email,state,city,doorstreet,address,pincode,billingaddress_check)
        .then(response => {
        if(response.status=== 200)
        {
          localStorage.setItem('continue_order',0)
         // localStorage.setItem('address_submit',0)
          
          let billingaddress_check  = billingAddress === false?0:1;

          localStorage.setItem('billing_check',billingaddress_check)
          console.log('billingaddress_check',billingaddress_check)
          console.log('billing chenck',localStorage.getItem('billing_check'))
          setSuccessMessage(response.message);
          setShipId(response.shiping_id);
          setContinue(1)
          localStorage.setItem('shipId',response.shiping_id);
          console.log('postShippingAddressAdd shipid',localStorage.getItem('shipId'))
          setTimeout(function(){
            shippingDetails();
         }, 1700);
          
         /* postBillingAddressDetails(editId,shipId)
         .then(response => {
          var count = Object.keys(response);
          console.log('shipingdetail_resp',response)
          if(count.length >=0){
          
            setName(response.shipping_addrss.customer_name);
            setMobile(response.shipping_addrss.customer_mobile);
           //setEmail(response.shipping_addrss.customer_mobile);
            setState(response.shipping_addrss.state);
            setCity(response.shipping_addrss.city);
            setDoorStreet(response.shipping_addrss.customer_address);
            setAddress(response.shipping_addrss.customer_address1);
            setPincode(response.shipping_addrss.pincode);
          }
          }) */
          
          /* window.sessionStorage.setItem("session_shipingid",response.shiping_id);
          localStorage.setItem('name',name);
          localStorage.setItem('mobile',mobile);
          localStorage.setItem('email',email);
          localStorage.setItem('state',state);
          localStorage.setItem('city',city);
          localStorage.setItem('doorstreet',doorstreet);
          localStorage.setItem('address',address);
          localStorage.setItem('pincode',pincode); */

          //const shippingData = {"name":name,"mobile":mobile,"email":email,"state":state,"city":city,"doorstreet":doorstreet,"address":address,"pincode":pincode,"shipId":response.shiping_id};
          //setCookie('SHIPPINGINFO', JSON.stringify({shipping_data:shippingData}));
          // console.log(shipId,'customer_add_id');
          // if (shipId!==undefined)
          // {
          //   const shippingData = {"name":name,"mobile":mobile,"state":state,"city":city,"doorstreet":doorstreet,"address":address,"pincode":pincode,"shipId":shipId};
          //   setCookie('SHIPPINGINFO', JSON.stringify({shipping_data:shippingData}));
          // }

          // setName('');
          // setMobile('');
          // setState('');
          // setCity('');
          // setDoorStreet('');
          // setAddress('');
          // setPincode('');
          // handleClose();
          toggleShowA()
        }
        else
        {
          // respError(response.message);
          // console.log('response',response);
          // respError(response.message);
        }
      },)
      //}
    };
    formError(error)
  }
  const onGuestSubmit = (e) => {
    /* if(cookies.GUESTINFO!==undefined){
     
        const cookies1 = new Cookies();
        cookies1.remove('GUESTINFO');
              
    } */
    //Cookies.remove('GUESTINFO');
    console.log('guest',guest);
    console.log('idddd',id);
    const error = {}
    if(guestname ==='') error.guestname = 'Enter name'
    if(guestphone ==='') error.guestphone = 'Enter phone number'
    else if(guestphone!=="" && !isValidMobileNumber(guestphone)) 
    {
      error.guestphone = ' Please enter valid mobile number';
    }

    console.log('key length',Object.keys(error).length);

    if(Object.keys(error).length === 0 && guest === 0)
    {
      postSaveGuestUser(guestname,guestphone)
      .then(response => {
        if(response.status=== 200)
        {

          setGuest(1)
          if(guestname!==undefined && guestphone!==undefined){
            localStorage.setItem('guest_username',guestname);
            localStorage.setItem('guest_phone',guestphone);
            localStorage.setItem('guestotp',response.org_otp);
          }
          console.log('guest OTP',response.org_otp)
          localStorage.setItem('guest_id',cookies.GUESTINFO.user_id);
          console.log('cookies guest data',cookies);

          
          localStorage.removeItem('billing_check')
          localStorage.removeItem("shipId")
	        //localStorage.removeItem("continue_order")
          /* if(cookies.GUESTINFO!==undefined){
     
            const cookies1 = new Cookies();
            cookies1.remove('GUESTINFO');
            console.log('guest in ')
          
          } */
          console.log('guestxxxx',cookies.GUESTINFO); 
          // setOrgOtp(response.org_otp);
        }
      },)
    }
    if(guest ===1)
    {
     
      //clearCookies();
      // if(guestotp ==='') error.guestotp = 'Enter otp'
      //localStorage.setItem('defaultGuest',1);

      if (id===0 || id!=='')
      {
        console.log('otp validateinnn ');

        const cookieData = {};
        cookieData.sessId = cookies.SESSIONINFO;
        if (cookieData.sessId === undefined || cookieData.sessId ==='')
        {
          const unique_id = uuid();
          //console.log(unique_id,'uuid');
          setCookie('SESSIONINFO', JSON.stringify(unique_id));
          cookieData.sessId = unique_id;
        }

        const guest_id =localStorage.getItem('guest_id',cookies.GUESTINFO!==undefined?cookies.GUESTINFO.user_id:'');

        console.log('cookies data',cookies);
        postGuestOTPValidate(guestphone,guestotp,cookieData.sessId,guest_id)
        .then(response => {
          if(response.status=== 200)
          {

            

            localStorage.setItem('defaultGuest',1);
            //console.log(response,'guest');
            if(cookies.GUESTINFO!==undefined||cookies.GUESTINFO!=='')
            {
              // console.log('sdsdsd');
              setCookie('GUESTINFO', JSON.stringify({user_id:response.guest_user_id}));
              // let path = `/MyProfile`; 
              setEditId(response.guest_user_id)

              console.log('guestotpresp',response)
              setGuestName('');
              setGuestPhone('');
              handleRegister()
              
              //setDefaultGuestUser(1)
              // navigate(path);
            }

          }
          else
          {
            setInvalidOTP(response.message);
          }
        },)
      }
    }
    guestformError(error)
  }

  const clearCookies = () => {
    if(guest===1){
      const cookies1 = new Cookies();
      cookies1.remove('GUESTINFO');
     // const id=0;
      console.log('cookie in id',id)
      console.log('cookie in gues',cookies.GUESTINFO)

    }
  }

  const shippingDetails = () => {
    let shipid = localStorage.getItem('shipId');
    console.log('shipdet id',shipid);
      postBillingAddressDetails(editId,shipid)
         .then(response => {
          var count = Object.keys(response);
          console.log('shipingdetail_resp',response)
          if(count.length >=0){
          
            let Check_billing = localStorage.getItem('billing_check')
            //console.log('shiiping check',Check_billing);
            if(Check_billing==1){
              setBillingAddress(true);
            }else{
              setBillingAddress(false);
            }
            //setBillingAddress(true);
            setName(response.shipping_addrss.customer_name);
            setMobile(response.shipping_addrss.customer_mobile);
            setEmail(response.shipping_addrss.customer_email);
            setDoorStreet(response.shipping_addrss.customer_address);
            setAddress(response.shipping_addrss.customer_address1);
            setPincode(response.shipping_addrss.pincode);
            setState(response.shipping_addrss.state_id);
            fetchCity(state);            
            setCity(state);

          }
        })
  }


  const fetchAddress = ()=>
  {
    //console.log('0000000');
    postAddressList(editId)
    .then(response => {
      console.log('postAddressList',response);
      if(response.status=== 200){
        if(response.address_list!==''){
          setAdd(response.address_list);
        }
        localStorage.setItem('guest_continue',0)
       // setAdd(response.address_list);
        {response.address_list !=='' && response.address_list.length > 0 && response.address_list.map((add,index)=>{
          if(add.defualt_address !==null && add.defualt_address==1){
            setDefCheck(add.customer_address_id);
            setIndex(index);
          }
        })}
      }
      if(cookies.SHIPPINGINFO !==undefined){
        const data = cookies.SHIPPINGINFO;
        console.log('data',data.shipping_data);
        setBillingAddress(false);
        setName('');
        setMobile('');
        setEmail('');
        setState('');
        setCity('');
        setDoorStreet('');
        setAddress('');
        setPincode('');
      }
      // else{
      //   console.log('sddsdsd');
      //   setName('');
      //   setMobile('');
      //   setState('');
      //   setCity('');
      //   setDoorStreet('');
      //   setAddress('');
      //   setPincode('');
      // }
      // if(response.address_list.length === 1)
      // {
      //   // console.log('one',response.address_list.length);
      //   postAddressDefault(id,defAddId)
      //   .then(response => {
      //     if(response.status=== 200)
      //     {
      //       // console.log('response',response);
      //     }
      //   },)
      // }
      
    },)
  }
  
  /* const Clearcookies = ()=> {

     const cookies = new Cookies();
            cookies1.remove('GUESTINFO');
            console.log('guest in ')
            console.log('guestxxxx',cookies.GUESTINFO);

  } */
   


  const addressEdit = (address_id,value)=> {
    // console.log('value',value.state);
    const error = {};
    formError(error);
    postBillingAddressDetails(editId,address_id)
    .then(response => {
      if(response.status=== 200 )
      {
        console.log('billingdetails_edit',response);
      }
    },)
    setShippingName(value.customer_name);
    setShippingMobile(value.customer_mobile);
    setShippingEmail(value.customer_email);

    setShippingDoorStreet(value.customer_address);
    setShippingAddress(value.customer_address1);
    setShippingPincode(value.pincode);
    setShippingState(value.state_id);
    fetchCity(value.state_id);
    setUpdateId(address_id);
    editShow();
    setShippingCity(value.city_id);
  }
  const onEdit = ()=> {
    
    const error = {}
    if(shippingname ==='') error.shippingname = 'Enter name'
    if(shippingmobile ==='') error.shippingmobile = 'Enter mobile number'
    else if(shippingmobile!=="" && !isValidMobileNumber(shippingmobile))
    {
      error.shippingmobile = ' Please enter valid mobile number';
    }
    if(shippingemail ==='') error.shippingemail = 'Enter email'
    else if(shippingemail!=="" && !isValidEmail(shippingemail))
    {
      error.shippingemail = ' Please enter valid email';
    }
    if(shippingstate ==='') error.shippingstate = 'Select State'
    if(shippingcity ==='') error.shippingcity = 'Select City'
    if(shippingdoorstreet ==='') error.shippingdoorstreet = 'Enter door no and street name'
    if(shippingpincode ==='') error.shippingpincode = 'Enter pincode'
    else if(shippingpincode !=='' && !isValidPincode(shippingpincode))
    {
     error.shippingpincode = 'Enter valid pincode'
    }
    if(shippingaddress ==='') error.shippingaddress = 'Enter address'
    if(Object.keys(error).length === 0)
    {
      postAddressUpdate(editId,updateId,shippingname,shippingmobile,shippingemail,shippingdoorstreet,shippingaddress,shippingstate,shippingcity,shippingpincode)
      .then(response => {
        if(response.status=== 200)
        {
          setSuccessMessage(response.message);
          toggleShowA();
          handleClose();
          fetchAddress();

          if(defaultCheck === updateId){
            billingaddress_clear();
          }

        }
      },)
    };
    formError(error)
  }
  
  const billingaddress_clear = () =>{
    if(billingAddress === true){
       setBillingAddress(false);
       setName('');
       setMobile('');
       setEmail('');
       setDoorStreet('');
       setAddress('');
       setPincode('');
       setState('');
       setCity('');
     }
     
   }

  const addDelete = ()=> {
   // console.log('defaultCheck',defaultCheck);
    if(defaultCheck === deleteId){
      setBillingAddress(false);
      setDefCheck(0);
      setName('');
      setMobile('');
      setEmail('');
      setState('');
      setCity('');
      setDoorStreet('');
      setAddress('');
      setPincode('');
    }
    let billingaddress_check  = billingAddress === false?0:1;
    postAddressDelete(editId,deleteId,billingaddress_check)
    .then(response => {
      if(response.status=== 200)
      {
        setSuccessMessage(response.message);
        toggleShowA();
        fetchAddress();
      }
    },)
    setShowConfirm(false);
    
    
  }
    
  useEffect(() => {
   // window.location.reload();

   // let Continue = localStorage.getItem('continue_order')
    //ssetAddresssubmit(localStorage.getItem('address_submit'))
    setContinue(localStorage.getItem('continue_order'))
    //setGuestcontinue(localStorage.getItem('guest_continue'))
    console.log('is iscontinue',iscontinue);

    console.log('useefect guest',guest);
    console.log('shipid',shipId)
    console.log('useeffect GUESTINFO',cookies.GUESTINFO)
    //localStorage.setItem('defaultGuest',);

    /* if (storedName) {
      setName(storedName);
    } else {
      setName('Guest'); // Setting a default value if 'name' is not found in local storage
    } */
    console.log('user id',id)
    //var test = localStorage.getItem('defaultGuest');
    //console.log('localStoragedefaultGuest ',test)
    //setDefaultGuest(defaultguest)
    /* if(cookies.GUESTINFO!=='' || cookies.GUESTINFO !==undefined){
      const cookies1 = new Cookies();
      cookies1.remove('GUESTINFO');

    console.log('GUESTINFO',cookies.GUESTINFO)
    
    } */

    console.log('default Guestuser',localStorage.defaultGuest)
    const Isguest= localStorage.getItem('guest_continue');

    let shipid = localStorage.getItem('shipId');
    if(shipid !==0 && cookies.LOGGEDINFO!==undefined){
      shippingDetails();
      setShipId(localStorage.getItem('shipId'))
      console.log('efect shipid',shipId)
    }else{
      if(cookies.LOGGEDINFO===undefined && Isguest ==1){
        shippingDetails();
        setShipId(localStorage.getItem('shipId'))
        console.log('xxxxxx shipid',shipId)
      }
    }

    const Session_shipId = window.sessionStorage.getItem('session_shipingid');

   // const city = localStorage.setItem('city',city);
   // console.log('session_shipingid',Session_shipId)
   // console.log('localStorage',localStorage)
   /*  if(Session_shipId!==undefined){

      setName(localStorage.name);
      setMobile(localStorage.mobile);
      setEmail(localStorage.email);
      setState(localStorage.state);
      fetchCity(localStorage.city)
      setCity(localStorage.city);
      setDoorStreet(localStorage.doorstreet);
      setPincode(localStorage.pincode);
      setAddress(localStorage.address);

    } */

    const cookieData = {};
    cookieData.couponcode = cookies.COUPONINFO;
    cookieData.shipinginfo = cookies.SHIPPINGINFO;
    if (cookieData.shipinginfo  !== undefined && defaultCheck !== updateId && defaultCheck !== deleteId)
    {
      setBillingAddress(true);
      setName(cookieData.shipinginfo.shipping_data.name);
      setMobile(cookieData.shipinginfo.shipping_data.mobile);
      setEmail(cookieData.shipinginfo.shipping_data.email);
      setState(cookieData.shipinginfo.shipping_data.state);
      fetchCity(cookieData.shipinginfo.shipping_data.state);
      setCity(cookieData.shipinginfo.shipping_data.city);
      setDoorStreet(cookieData.shipinginfo.shipping_data.doorstreet);
      setPincode(cookieData.shipinginfo.shipping_data.pincode);
      setAddress(cookieData.shipinginfo.shipping_data.address);
      setShipId(cookieData.shipinginfo.shipping_data.shipId);

    }
    
    if (cookieData.couponcode  !== undefined)
    {
      setCouponCode(cookieData.couponcode.coupon_code);
      setCouponMessage(cookieData.couponcode.message);
      setCouponAmount(cookieData.couponcode.amount);
    }
    else
    {
      setCouponCode('');
      setCouponMessage('');
      setCouponAmount('');
    }
    
  },[add,setAdd,coupon_code,coupon_amount,coupon_message]);
  const addressDelete = (address_id)=> {
    setShowConfirm(true);
    setDeleteId(address_id);
   // if(address_id === shipId){}
  }
  const setDefaultindex = (index,address_id) =>{
    setIndex(index);
    setDefAddId(address_id);
    //setDefCheck(1);
    postAddressDefault(editId,address_id)
    .then(response => {
      if(response.status=== 200)
      {
        console.log('billingAddress',billingAddress);
        if(billingAddress === true){
          setBillingAddress(false);
          setName('');
          setMobile('');
          setEmail('');
          setState('');
          setCity('');
          setDoorStreet('');
          setAddress('');
          setPincode('');
          
        }
        setDefaultAddress(address_id)
        setDefCheck(address_id);  
        //defaultValuecheck(address_id);
        // console.log('response',defaultCheck); 
      }
    },)
  }
  /* const defaultValuecheck = (address_id) => {

    if(billingAddress=== true){
    }
  } */

  //console.log('add',add);
  const getAddress = () => {
    const content=[];
    console.log('adddress add',add);
    //window.location.reload();
    {add !=='' && add.length > 0 && add.map((value,index) => (
      content.push(<Col lg={6} md={6}>
      <div className="address-box">
        <h5>{value.customer_name}</h5>
        <p className='mb-4'>{value.customer_address} {value.customer_address1}, {value.city}, {value.state} - {value.pincode} </p>                            
        <div className='address-footer'>                       
            <div className='address-edit'>
            <Form.Check inline label="Default" name="group1" id={index} value={defaultCheck} checked={defaultCheck == value.customer_address_id} type='radio' onClick = {(e)=>setDefaultindex(index,value.customer_address_id)}/>
              {/* {value.defualt_address==="1"?
              <Form.Check inline label="Default" name="group1" id="1" checked="checked" type='radio'/>:<Form.Check inline label="Default" name="group1" id="1" type='radio'  onClick = {(e)=>setDefaultindex(index,value.customer_address_id)}/>} */}
              <Link href="#" onClick={(e) => addressDelete(value.customer_address_id)} className="delete"><Icon icon="mdi:delete"/> Delete</Link>
              <Link className="edit" onClick={(e) => addressEdit(value.customer_address_id,value)} ><Icon icon="mdi:edit"/> Edit</Link>
            </div>             
        </div>  
      </div>     
    </Col>)
    ))}
    return content;
  }

  useEffect(() => {
    
    const Isguest= localStorage.getItem('guest_continue');
    fetchCartdata();
    if(cookies.LOGGEDINFO!==undefined || Isguest ==1){
      fetchAddress();
    }
   // fetchAddress();
    postStateSave()
    .then(response => {
      if(response.status=== 200 && response.State_list.length > 0)
      setStates(response.State_list);
    },)
    fetchCity(state);
  },[]);
  
  const getBillingAddress = (e) =>{
    // console.log(e.target,'target');
    // postBillingAddressDetails(editId,bill_id)
    // setBillingAddress(1)
    // console.log('defaultCheck',defaultCheck);
    if (defaultCheck !==0)
    {
      if(e.target.checked === true)
      {
        setBillingAddress(true);
        setName(add[publicIndex].customer_name);
        setMobile(add[publicIndex].customer_mobile);
        setEmail(add[publicIndex].customer_email);
        setDoorStreet(add[publicIndex].customer_address);
        setAddress(add[publicIndex].customer_address1);
        setPincode(add[publicIndex].pincode);
        setState(add[publicIndex].state_id);
        fetchCity(add[publicIndex].state_id);
        setCity(add[publicIndex].city_id);
      }
      else
      {
        setBillingAddress(false);
        setName('');
        setMobile('');
        setEmail('');
        setDoorStreet('');
        setAddress('');
        setPincode('');
        setState('');
        setCity('');
      }
    }
    else
    {
      setDefaultCheck(true);
      e.target.checked = false
    }
  }
  const getShippingState = ()=> {
    const content = [];
    states.map((value,index) => (
      content.push(<option key={index} value={value.state_id}>{value.state_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example" value={shippingstate} onChange = {onChangeShippingState} name="shippingstate"> <option value="">Select State</option>{content}</Form.Select></>;
  }
  const getState = ()=> {
    const content = [];
    states.map((value,index) => (
      content.push(<option key={index} value={value.state_id}>{value.state_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example" value={state} onChange = {onChangeState} name="state"> <option value="">Select State</option>{content}</Form.Select></>;
  }
  const getShippingCity = ()=> {
    const content = [];
    cities.map((value,index) => (
      content.push(<option key={index} value={value.city_id}>{value.city_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example"
     onChange = {onChangeShippingCity} value={shippingcity}
     name="shippingcity">  <option value="">Select City</option>{content}</Form.Select></>;
  }
  const getCity = ()=> {
    const content = [];
    cities.map((value,index) => (
      content.push(<option key={index} value={value.city_id}>{value.city_name}</option>)
    ))
    return <><Form.Select aria-label="Default select example"
     onChange = {onChangeCity} value={city}
     name="city">  <option value="">Select City</option>{content}</Form.Select></>;
  }
  const getDefaultChecked = ()=>
  {
    const error = {}
    if(state==='' || city===''){
      if(state ==='' ) error.state = 'Select State'
      if(city ==='') error.city = 'Select City'
      formError(error);
      return false;
    }

    if(defaultCheck === 0){
      setDefaultCheck(true);
    }else{
        
        console.log(shipId,'ship_Id');
        let path =`/confirmorder?s_Id=${shipId}`
        navigate(path);
      
      // const cookieData = {};
      // cookieData.couponcode = cookies.COUPONINFO;
      // cookieData.shipinginfo = cookies.SHIPPINGINFO;
      // if (cookieData.shipinginfo  !== undefined)
      // {
      //   setBillingAddress(true);
      //   setName(cookieData.shipinginfo.shipping_data.name);
      //   setMobile(cookieData.shipinginfo.shipping_data.mobile);
      //   setState(cookieData.shipinginfo.shipping_data.state);
      //   fetchCity(cookieData.shipinginfo.shipping_data.state);
      //   setCity(cookieData.shipinginfo.shipping_data.city);
      //   setDoorStreet(cookieData.shipinginfo.shipping_data.doorstreet);
      //   setPincode(cookieData.shipinginfo.shipping_data.pincode);
      //   setAddress(cookieData.shipinginfo.shipping_data.address);
      //   setShipId(cookieData.shipinginfo.shipping_data.shipId);

      // }
     
    }
    
    // defaultCheck===1?navigate(path):setDefaultCheck(true)
    //navigate(path);
  }
  const onApply = ()=>
  {
    const error = {}
    if(cc ==='') error.cc = 'Enter Coupon Code'
    setInvalidCC("");
    formError(error);
    if(Object.keys(error).length === 0)
    {
      postCouponCode(editId,cc)
      .then(response => {
        if(response.status=== 200)
        {
          setCCAmount(response.amt);
          setCCApplied(response.message);
          const cookieData = {};
          setCookie('COUPONINFO', JSON.stringify({coupon_code:cc,message:response.message,amount:response.amt}));
          cookieData.couponcode = cookies.COUPONINFO;
          setCouponCode(cookies.COUPONINFO.coupon_code);
          setCouponAmount(cookies.COUPONINFO.amount);
          setCouponMessage(cookies.COUPONINFO.message);
        }
        else
        {
          setCCApplied("");
          setInvalidCC("Invalid Coupon code");
          setCouponCode("");
          setCouponAmount("");
          setCouponMessage("");
        }
      },)
      setCouponCode(cookies.COUPONINFO.coupon_code);
      setCouponAmount(cookies.COUPONINFO.amount);
      setCouponMessage(cookies.COUPONINFO.message);
    }
  }
  const onChangeOtp = (e) => {
    const {value} = e.target;
    const re =/^[0-9]*$/;
    if (value === "" || re.test(value)) {
      setGuestOtp(e.target.value)
    }
  }
  const resendOTP = ()=>{
    setGuestOtp('');
    setInvalidOTP('');
    postResendOTP(guestphone,"guest_otp")
    .then(response => {
      if(response.status=== 200)
      {
        // setOtp(response.otp);
        // respSuccess(response.message);
        // handleShow()
        // let path = `/confirmpassword?mob_num=${mobile}`; 
        // navigate(path);
      }
    },)
  }
  
  return (

    <div>
        <Header />
        <Toast show={showA} onClose={toggleShowA} className='toast-success' delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""  
            />
            <strong className="me-auto">{successMessage}</strong>
          </Toast.Header>
        </Toast>
        <section className="section header-nxt checkout-page">
          <Container fluid="md">
            <div className="main-heading3">
              <h3>Checkout</h3>
            </div>
           
            {cookies.LOGGEDINFO === undefined? <Card className="card cart mt-0 guest-user mb-3">              
                {localStorage.defaultGuest==0 /* cookies.GUESTINFO===undefined */?<><Card.Header>
                Guest User Login
                </Card.Header>
                <Card.Body>
                  <div className='coupon-box form-style1 mb-0'>
                     <Row>
                      <Col lg={4}>
                      <Form.Group className="form-group" controlId="">
                        <Form.Label>Name <span className="req">*</span></Form.Label>
                        <Form.Control type="text" maxLength="30" placeholder="Enter Name" name="guestname" defaultValue={guestname} onChange = {onChangeGuest} />
                        {guestformerrors.guestname!=='' && guestformerrors.guestname!==undefined && (<Form.Text className='error text-danger'>{guestformerrors.guestname}</Form.Text>)}
                      </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="form-group" controlId="">
                          <Form.Label>Phone No. <span className="req">*</span></Form.Label>
                          <Form.Control type="text" placeholder="Enter Phone No." name="guestphone" maxLength="10" defaultValue={guestphone} onChange = {onChangeGuest} />
                          {guestformerrors.guestphone!=='' && guestformerrors.guestphone!==undefined && (<Form.Text className='error text-danger'>{guestformerrors.guestphone}</Form.Text>)}
                        </Form.Group>
                      </Col>
                      <Col lg={5}>
                        <Row>
                        {guest===1?<Col lg={5}>
                          <Form.Group className="form-group" controlId="">
                            <Form.Label>OTP <span className="req"></span></Form.Label>
                            <Form.Control type="text" onChange={onChangeOtp}  value={guestotp} autoComplete="off" maxLength={4} />
                            {guestformerrors.guestotp!=='' && guestformerrors.guestotp!==undefined && (<Form.Text className='error text-danger'>{guestformerrors.guestotp}</Form.Text>)}
                            {invalidOTP?<Form.Text className='error text-danger'>{invalidOTP}</Form.Text>:""}
                          </Form.Group>
                          <div className="forgot-row mt-2 mb-3">
                            <Link to={`/checkout`} onClick={(e)=>resendOTP()} className="fwd-password resend-otp"><Icon icon="solar:refresh-linear" /> Resend OTP</Link>
                          </div>
                          </Col>
                          :''}
                          <Col lg={4}>
                            <Button variant="primary" className='btn btn-primary w-100' onClick={onGuestSubmit}>Submit </Button>
                          </Col>
                        </Row>
                      </Col>
                     </Row>                      
                  </div> 
                </Card.Body></>:''}
              </Card>:""}

            {(localStorage.defaultGuest==1 || cookies.LOGGEDINFO!==undefined /* || cookies.GUESTINFO!==undefined */)?
            <Row>
              <Col lg={8}>
              <Card className="card-style1 address-card">
                  <Card.Header className='card-header-group'>
                      <span> Delivery Address</span>
                  </Card.Header>
                  <Card.Body className='card-scroll pb-4'>
                    <Row>
                    <Col lg={9}> 
                      <Row >
                        {getAddress()}
                      </Row>
                    </Col>                   
                      <Col lg={3} md={6}>
                        <div className="add-address">
                          <Link onClick={handleShow}>
                            <Icon icon="ph:plus-circle" /> 
                            <p>Add Address</p>
                          </Link>
                        </div>             
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>       

                <Card className='card-style1 billing-card mt-4'>
                  <Card.Header className='card-header-group'>
                    <span> Billing Address</span>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check checked={billingAddress} onChange={(e)=>getBillingAddress(e)} type="checkbox" label=" Use as billing address" />
                    </Form.Group>
                  </Card.Header>
                  <Card.Body>
                    <div className='form-style1'>
                        <Form >
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Name <span className="req">*</span></Form.Label>
                                <Form.Control type="text" maxLength="30" name="name" value={name} onChange = {onChange} placeholder="Enter Name" />
                                {formErrors.name!=='' && formErrors.name!==undefined && (<Form.Text className='error text-danger'>{formErrors.name}</Form.Text>)}

                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Mobile No. <span className="req">*</span></Form.Label>
                                <Form.Control type="text" name="mobile" maxLength='10' value={mobile} placeholder="Enter Mobile No." onChange = {onChange} />
                                {formErrors.mobile!=='' && formErrors.mobile!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobile}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Email <span className="req">*</span></Form.Label>
                                <Form.Control type="email" name="email" value={email} placeholder="Enter Email" onChange = {onChange} />
                                {formErrors.email!=='' && formErrors.email!==undefined && (<Form.Text className='error text-danger'>{formErrors.email}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>State <span className="req">*</span></Form.Label>
                                {getState()}
                                {formErrors.state!=='' && formErrors.state!==undefined && (<Form.Text className='error text-danger'>{formErrors.state}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>City <span className="req">*</span></Form.Label>
                                {getCity()}
                                {formErrors.city!=='' && formErrors.city!==undefined && (<Form.Text className='error text-danger'>{formErrors.city}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Door No. / Street <span className="req">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter Street" onChange = {onChange} name="doorstreet" value={doorstreet} />
                                {formErrors.doorstreet!=='' && formErrors.doorstreet!==undefined && (<Form.Text className='error text-danger'>{formErrors.doorstreet}</Form.Text>)}
                              </Form.Group>
                            </Col>                      
                            <Col lg={6}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Pincode <span className="req">*</span></Form.Label>
                                <Form.Control type="text" maxLength="6" name="pincode" value={pincode} placeholder="Enter Pincode" onChange = {onChange} />
                                {formErrors.pincode!=='' && formErrors.pincode!==undefined && (<Form.Text className='error text-danger'>{formErrors.pincode}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="form-group" controlId="">
                                <Form.Label>Address <span className="req">*</span></Form.Label>
                                <Form.Control as="textarea" name="address" value={address} rows={3} placeholder="Enter Address" onChange = {onChange} />
                                {formErrors.address!=='' && formErrors.address!==undefined && (<Form.Text className='error text-danger'>{formErrors.address}</Form.Text>)}
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <div className='button-group mt-3'>
                                <Link className="btn btn-md btn-primary" to="" type="submit" onClick={onShippingSubmit} >Submit</Link>
                                {/* <Link href="#" className='cancel-text'>Reset</Link> */}
                              </div>
                            </Col>
                          </Row>
                        </Form>
                    </div>
                  </Card.Body>
                </Card>  
              </Col>
              <Col lg={4}>
              {/* {id===0?
              <Card className="card cart mt-0 guest-user mb-3">
                <Card.Header>
                Guest User Login
                </Card.Header>
                <Card.Body>
                  <div className='coupon-box form-style1 mb-0'>
                      <Form.Group className="form-group" controlId="">
                        <Form.Label>Name <span className="req">*</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" name="guestname" defaultValue={guestname} onChange = {onChangeGuest} />
                        {guestformerrors.guestname!=='' && guestformerrors.guestname!==undefined && (<Form.Text className='error text-danger'>{guestformerrors.guestname}</Form.Text>)}
                      </Form.Group>
                      
                      <Form.Group className="form-group" controlId="">
                        <Form.Label>Phone No. <span className="req">*</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter Phone No." name="guestphone" defaultValue={guestphone} onChange = {onChangeGuest} />
                        {guestformerrors.guestphone!=='' && guestformerrors.guestphone!==undefined && (<Form.Text className='error text-danger'>{guestformerrors.guestphone}</Form.Text>)}
                      </Form.Group>
                      {guest===1?<Form.Group className="form-group" controlId="">
                        <Form.Label>OTP <span className="req"></span></Form.Label>
                        <Form.Control type="text" onChange={onChangeOtp} autoComplete="off" maxLength={4} />
                      </Form.Group>:''}
                      <div className='mt-2'>
                        <Button variant="primary" className='btn btn-lg btn-primary w-100' onClick={onGuestSubmit}>Continue as Guest </Button>
                      </div>
                  </div> 
                </Card.Body>
              </Card>:''} */}

                <Card className="card cart mt-0">
                  <Card.Header>
                   Order summary
                  </Card.Header>
                  <Card.Body>
                    {cookies.COUPONINFO?<div className='coupon-box'>
                        <h3>Coupon code</h3>
                        <p>If you have a coupon code, please enter it in <br/> the box below</p>
                        <InputGroup>
                          <Form.Control
                            placeholder="Enter Coupon Code"
                            name="cc"
                            value={cookies.COUPONINFO?coupon_code:cc}
                            onChange = {onChangeCC}
                            aria-label=""
                            aria-describedby=""
                          />
                           {/*<Button variant="primary" onClick = {(e)=>onApply()}><Icon icon="ph:gift-light" /> Apply </Button > */}
                        </InputGroup>
                        {formErrors.cc!=='' && formErrors.cc!==undefined && (<Form.Text className='error text-danger'>{formErrors.cc}</Form.Text>)}
                        {invalidcc!==cc?<Form.Text className='error text-danger'>{invalidcc}</Form.Text>:''}
                        <Form.Text className='error text-success text-bold'>{cookies.COUPONINFO?coupon_message:ccApplied}</Form.Text>
                    </div>:''}
                    <p className="desc">Shipping and additional costs are calculated based on values you have entered.</p>
                    <div className="label-price mb-3">
                      <p>Order Subtotal  <span>₹{getSubTotal()?getSubTotal():0}</span></p>
                      {/* <p>Shipping and handling <span>₹{shipCost}</span></p> */}
                      <p>Coupon <span>₹{cookies.COUPONINFO?coupon_amount:ccAmount}</span></p>
                      {/* <p>Tax (5%) <span>₹25.00</span></p> */}
                      <p className="bold">Amount Payable <span>₹{getAmountPayable()?getAmountPayable():0}</span></p>
                    </div>
                    {shipId!==0 && iscontinue==1?<Button className="btn btn-lg btn-primary w-100" onClick=
                    {(e)=> getDefaultChecked()}
                    >Continue Order</Button>:''}
                  </Card.Body>
                </Card>
              </Col>
            </Row>:''}
          </Container>
        </section>
        <Footer />


        {/* Add New Address Modal */}
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Name <span className="req">*</span></Form.Label>
                  <Form.Control type="text" maxLength="30" placeholder="Enter Name" onChange = {onChangeShipping} name="shippingname" value={shippingname} />
                  {formErrors.shippingname!=='' && formErrors.shippingname!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingname}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Mobile No. <span className="req">*</span></Form.Label>
                  <Form.Control type="text" maxLength="10" name="shippingmobile" value={shippingmobile} placeholder="Enter Mobile No." onChange = {onChangeShipping} />
                  {formErrors.shippingmobile!=='' && formErrors.shippingmobile!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingmobile}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Email <span className="req">*</span></Form.Label>
                  <Form.Control type="email" placeholder="Enter Email" onChange = {onChangeShipping} name="shippingemail" value={shippingemail} />
                  {formErrors.shippingemail!=='' && formErrors.shippingemail!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingemail}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>State <span className="req">*</span></Form.Label>
                  {getShippingState()}
                  {formErrors.shippingstate!=='' && formErrors.shippingstate!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingstate}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>City <span className="req">*</span></Form.Label>
                  {getShippingCity()}
                  {formErrors.shippingcity!=='' && formErrors.shippingcity!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingcity}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Door No. / Street <span className="req">*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Street" onChange = {onChangeShipping} name="shippingdoorstreet" value={shippingdoorstreet} />
                  {formErrors.shippingdoorstreet!=='' && formErrors.shippingdoorstreet!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingdoorstreet}</Form.Text>)}
                </Form.Group>
              </Col>                            
              <Col lg={6}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Pincode <span className="req">*</span></Form.Label>
                  <Form.Control type="text" maxLength="6" name="shippingpincode" value={shippingpincode} placeholder="Enter Pincode" onChange = {onChangeShipping} />
                  {formErrors.shippingpincode!=='' && formErrors.shippingpincode!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingpincode}</Form.Text>)}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="form-group" controlId="">
                  <Form.Label>Address <span className="req">*</span></Form.Label>
                  <Form.Control as="textarea" rows={3} name="shippingaddress" value={shippingaddress} placeholder="Enter Address" onChange = {onChangeShipping} />
                  {formErrors.shippingaddress!=='' && formErrors.shippingaddress!==undefined && (<Form.Text className='error text-danger'>{formErrors.shippingaddress}</Form.Text>)}
                </Form.Group>
              </Col>
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Close
            </Button>
            {!isEdit && (<Button variant="primary" disabled={isDisabled} onClick={onSubmit}>Save Address</Button>)}
            {isEdit && (<Button variant="primary" onClick={(e) =>onEdit(add[publicIndex].customer_address_id)}>Update Address</Button>)}
          </Modal.Footer>
        </Modal>

        {/* Delete confirmation Modal */}
        <Modal show={showConfirm} onHide={confirmClose} size="md" className='modal-alert-option'>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'>
            <Row>
              Are you sure want to delete?
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={(e) => addDelete()}>
              Ok
            </Button>
            <Button variant="light" onClick={confirmClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Check Default address Modal */}
        <Modal show={showDefaultCheck} onHide={defaultCheckClose} size="md" className='modal-alert-option'>
          <Modal.Header closeButton>
            <Modal.Title>Default Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              Please select a default address
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={(e) => defaultCheckClose()}>
              Ok
            </Button>
            <Button variant="light" onClick={defaultCheckClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

    </div>
  );
};

export default Checkout;
