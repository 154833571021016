import React,{useEffect,useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Icon } from '@iconify/react';
import Card from 'react-bootstrap/Card';
// import { format } from 'date-fns';
import dateFormat from "dateformat";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SideMenu from "../Components/SideMenu";
import { Link,useNavigate } from 'react-router-dom';
import {useCookies} from 'react-cookie';

import { postOrderListing } from '../services/serviceapi';

const MyOrders = () => {
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO',"COUPONINFO","GUESTINFO"]);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const navigate = useNavigate();


  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;


  const fetchOrderListing = ()=> {
    postOrderListing(id)
    .then(response => {
      if(response.status=== 200)
      {
        console.log(response.order_list,'response');
        setOrders(response.order_list)
        setOrderDetails(response.order_details)
      }
    },)
    // const orderdate = orderDetails
  }
  // const getOrderDetails = (invoice_id)=>{
  //   let path = `/orderdetails?iv_Id=${invoice_id}`
  //   navigate(path);
  // }
  const getProductList = (product)=>{
    const content=[];
    console.log(product,'product');
    product.map((value,index)=> (
      content.push(<div className='bulk-row'>
      <Row>
        <Col lg={3} xs={4} md={3}>
          <div className="thumb">
            <Link to= {`/orderdetails?Iv_id=${value.invoice_id}`}>
              <img src={value.product_image} className="img-fluid" alt="" title=""></img></Link>
          </div>
        </Col>
        <Col lg={5} xs={8} md={5}>
          <div className='delivery-product'>
            <h5><Link to={`/orderdetails?Iv_id=${value.invoice_id}`}>{value.product_name} - {value.variant_name} {value.unit_name}</Link></h5>
            <p><strong>Quantity :</strong> {value.quantity}</p>
            <p><strong>Price per unit :</strong> ₹{value.product_price}</p>
          </div>
        </Col>
      </Row>
    </div>)
    ))
    return content;
  }
  console.log('orders',orders);
  const getOrderList = ()=>{
    const content=[];
    orders.map((value,index) => (
      content.push(<Card>

        <Card.Header>
          <Row>
            <Col lg={6} xs={6} md={6}>
              <Link to={`/orderdetails?Iv_id=${orderDetails[index].invoice_id}`}className='order-id'>Order ID: <strong>{orderDetails[index].invoice_id}</strong></Link>
            </Col>
            <Col lg={6} xs={6} md={6}>
              {/* <Link to="/TrackOrders" className='track-link'><Icon icon="mdi:map-marker-outline" /> Track</Link> */}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          
          <Row>
            <Col lg={7} md={7} xs={12}>
              {getProductList(value)}
                

                {/* <div className='bulk-row'>
                  <Row>
                    <Col lg={3} xs={4} md={3}>
                      <div className="thumb">
                        <Link to="/OrderDetails"><img src="assets/images/fruitomalt.png" className="img-fluid" alt="" title=""></img></Link>
                      </div>
                    </Col>
                    <Col lg={5} xs={8} md={5}>
                      <div className='delivery-product'>
                        <h5><Link to="/OrderDetails">Fruit O Malt - 250 G</Link></h5>
                        <p><strong>Quantity :</strong> 1</p>
                        <p><strong>Price per unit :</strong> ₹300</p>
                      </div>
                    </Col>
                  </Row>
                </div> */}
            </Col>

            <Col lg={5} md={5} xs={12}>
                <div className='delivery-content'>
                  {/* <p><strong>Delivered on Tuesday,March 25th 2023</strong></p> */}
                  {/* <p className='text-success'>Your item has been delivered</p> */}
                </div>
            </Col>

          </Row>                           

        </Card.Body> 
        <Card.Footer>
          <Row>
            <Col lg={6} xs={12} md={6}>
              <p>Ordered On {dateFormat(orderDetails[index].order_date,"dddd, mmmm dS, yyyy") } </p>
            </Col>
            <Col lg={6} xs={12} md={6}>
              <p className='text-end'>Order Total <strong>₹{orderDetails[index].total_order_price}</strong></p>
            </Col>
          </Row>
        </Card.Footer>
      </Card> )
    ))
    return content;
  }

  useEffect(() => {
    fetchOrderListing()
	},[]);

  return (
    <div>
        <Header />
        <section className="section header-nxt my-orders">
          <Container fluid="md">
            <Row>
              <Col lg={3} md={3}>
                <SideMenu />                
              </Col>
              <Col lg={9} md={9}>
                <div className='account-right'>
                  <h3 className='main-heading2'>My Orders</h3>
                  <div className='order-cover'>
                    {/* Start */}
                    <div className='order-row bulk-order'>
                      {getOrderList()}

                        {/* <Card>

                          <Card.Header>
                            <Row>
                              <Col lg={6} xs={6} md={6}>
                                <Link to="/OrderDetails" className='order-id'>Order ID: <strong>MA98543546786</strong></Link>
                              </Col>
                              <Col lg={6} xs={6} md={6}>
                                <Link to="/TrackOrders" className='track-link'><Icon icon="mdi:map-marker-outline" /> Track</Link>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col lg={7} md={7} xs={12}>
                                  <div className='bulk-row'>
                                    <Row>
                                      <Col lg={3} xs={4} md={3}>
                                        <div className="thumb">
                                          <Link to="/OrderDetails">
                                            <img src="assets/images/beetomalt.png" className="img-fluid" alt="" title=""></img></Link>
                                        </div>
                                      </Col>
                                      <Col lg={5} xs={8} md={5}>
                                        <div className='delivery-product'>
                                          <h5><Link to="/OrderDetails">Beet O Malt Plus - 250 G</Link></h5>
                                          <p><strong>Quantity :</strong> 2</p>
                                          <p><strong>Price per unit :</strong> ₹300</p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div className='bulk-row'>
                                    <Row>
                                      <Col lg={3} xs={4} md={3}>
                                        <div className="thumb">
                                          <Link to="/OrderDetails"><img src="assets/images/fruitomalt.png" className="img-fluid" alt="" title=""></img></Link>
                                        </div>
                                      </Col>
                                      <Col lg={5} xs={8} md={5}>
                                        <div className='delivery-product'>
                                          <h5><Link to="/OrderDetails">Fruit O Malt - 250 G</Link></h5>
                                          <p><strong>Quantity :</strong> 1</p>
                                          <p><strong>Price per unit :</strong> ₹300</p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                              </Col>

                              <Col lg={5} md={5} xs={12}>
                                  <div className='delivery-content'>
                                    <p><strong>Delivered on Tuesday,March 25th 2023</strong></p>
                                    <p className='text-success'>Your item has been delivered</p>
                                  </div>
                              </Col>

                            </Row>                           

                          </Card.Body> 
                          <Card.Footer>
                            <Row>
                              <Col lg={6} xs={12} md={6}>
                                <p>Ordered On Wed, March 20th 2023</p>
                              </Col>
                              <Col lg={6} xs={12} md={6}>
                                <p className='text-end'>Order Total <strong>₹900</strong></p>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card> */}
                    </div>

                    {/* <div className='order-row'>
                        <Card>
                          <Card.Header>
                            <Row>
                              <Col lg={6} xs={6} md={6}>
                                <Link to="/OrderDetails"
                                 className='order-id'>Order ID: <strong>MA98543546786</strong></Link>
                              </Col>
                              <Col lg={6} xs={6} md={6}>
                                <Link to="/TrackOrders" className='track-link'><Icon icon="mdi:map-marker-outline" /> Track</Link>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col lg={2} xs={4} md={3}>
                                <div className="thumb">
                                  <Link to="/OrderDetails"><img src="assets/images/fruitomalt.png" className="img-fluid" alt="" title=""></img></Link>
                                </div>
                              </Col>
                              <Col lg={5} xs={8} md={5}>
                                <div className='delivery-product'>
                                  <h5><Link to="/OrderDetails">Fruit O Malt - 250 G</Link></h5>
                                  <p><strong>Quantity :</strong> 2</p>
                                  <p><strong>Price per unit :</strong> ₹300</p>
                                </div>
                              </Col>
                              <Col lg={5} xs={12} md={4}>
                                <div className='delivery-content'>
                                  <p><strong>Delivered on Tuesday,March 25th 2023</strong></p>
                                  <p className='text-warning'>Shipping in progress</p>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <Row>
                              <Col lg={6} xs={12} md={6}>
                                <p>Ordered On Wed, March 20th 2023</p>
                              </Col>
                              <Col lg={6} xs={12} md={6}>
                                <p className='text-end'>Order Total <strong>₹600</strong></p>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card>
                    </div> */}
                    
                  </div>
                </div>

                {/* {orders.length===0?<div className="cart-empty d-none">
                  <img src="assets/images/no-record.png" className="img-fluid" alt="" title=""></img>
                  <h5 className='mt-3'>No Record Found...</h5>
                </div>:''} */}
                {orders.length===0?<div className="cart-empty empty-alert">
                    <img src="/assets/images/order-empty.png" className="img-fluid" alt="" title=""></img>
                    <h5><br></br>No Orders</h5>
                    <p>You don't have any new orders in your history</p>
                  </div>:''}

              </Col>
            </Row>           

          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default MyOrders;
