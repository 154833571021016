import React,{useState,useEffect} from 'react';
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';
import Button from 'react-bootstrap/Button';
import {useCookies,Cookies} from 'react-cookie';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import SideMenu from "../Components/SideMenu";
import { postChangePassword } from '../services/serviceapi';

const ChangePassword = () => {
  const [oldpassword, setOldPwd] = useState("");
  const [newpassword, setNewPwd] = useState("");
  const [confirmpassword, setConfirmPwd] = useState("");
  const [oldpasswordType, setOldPasswordType] = useState("password");
  const [newpasswordType, setNewPasswordType] = useState("password");
  const [confirmpasswordType, setConfirmPasswordType] = useState("password");
  const [passwordsuccessmessage, setPwdSuccessMessage] = useState("");
  const [passwordfailmessage, setPwdFailMessage] = useState("");
  const navigate = useNavigate();


  const defData = {oldpassword:'',newpassword:'',confirmpassword:''};
  const [formErrors,formError] = useState(defData);
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO',"COUPONINFO"]);

  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;

  const onChange = (e) => {
    const eTarget = e.target;
    switch(eTarget.name) {
    case 'oldpassword':
      setOldPwd(eTarget.value)
    break;
    
    case 'newpassword':
      setNewPwd(eTarget.value)
    break;

    case 'confirmpassword':
      setConfirmPwd(eTarget.value)
    break;
    
    default:
    break;
   }
 }
 function isCheckPassword(str)
  {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return re.test(str);
  }

  const onSubmit = (e) => {
    const error = {}
    if(oldpassword==='') error.oldpassword = 'Enter Old Password'
   
    if(newpassword==='') error.newpassword = 'Enter New Password'
    else if(newpassword!=="" && !isCheckPassword(newpassword))
    {
      error.newpassword = ' Password should have atleast 6 characters includes atleast one symbol, caps and numeric';
    }
    if(confirmpassword==='') error.confirmpassword = 'Enter New Password'
    else if(confirmpassword!=="" && !isCheckPassword(confirmpassword))
    {
      error.confirmpassword = ' Password should have atleast 6 characters includes atleast one symbol, caps and numeric';
    }
    else if(newpassword!==confirmpassword)
    {
      error.confirmpassword = "Password doesn't match";
    }
    formError(error)
    if(Object.keys(error).length === 0 && newpassword===confirmpassword)
    {
      postChangePassword(id,oldpassword,newpassword)
      .then(response => {
        if(response.status=== 200)
        {
          setPwdSuccessMessage(response.message)
          const cookies1 = new Cookies();
          cookies1.remove('LOGGEDINFO');
          let path = "/login"; 
          navigate(path);
        }
        else
        {
          setPwdFailMessage(response.message)
        }
      },)
    }
  }

  const togglePassword =()=>{
    if(oldpasswordType==="password")
    {
      setOldPasswordType("text");
    }else{
      setOldPasswordType("password");
    }
  }
  const toggleNewPassword =()=>{
    if(newpasswordType==="password")
    {
      setNewPasswordType("text");
    }else{
      setNewPasswordType("password");
    }
  }
  const toggleConfirmPassword =()=>{
    if(confirmpasswordType==="password")
    {
      setConfirmPasswordType("text");
    }else{
      setConfirmPasswordType("password");
    }
  }

  return (
    <div>
        <Header />
        <section className="section header-nxt my-orders">
          <Container fluid="md">
            <Row>
              <Col lg={3} md={3}>
                <SideMenu />                
              </Col>
              <Col lg={9} md={9}>
                <div className='account-right'>
                  <h3 className='main-heading2'>Change Password</h3>
                  <div className='form-style1 form-half'>
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="form-group" controlId="">
                          <Form.Label>Old Password <span className="req">*</span></Form.Label>
                          <Form.Control type={oldpasswordType} placeholder="Enter Old Password" name="oldpassword" defaultValue={oldpassword} onChange={onChange} />
                          <div onClick={togglePassword} className="eye">{(oldpasswordType==="password")?(<Icon icon="mdi:eye-off" />):<Icon icon="mdi:eye" />}</div>
                          {formErrors.oldpassword!=='' && formErrors.oldpassword!==undefined && (<Form.Text className='error text-danger'>{formErrors.oldpassword}</Form.Text>)}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group" controlId="">
                          <Form.Label>New Password <span className="req">*</span></Form.Label>
                          <Form.Control type={newpasswordType} placeholder="Enter New Password" name="newpassword" defaultValue={newpassword} onChange={onChange} />
                          <div onClick={toggleNewPassword} className="eye">{(newpasswordType==="password")?(<Icon icon="mdi:eye-off" />):<Icon icon="mdi:eye" />}</div>
                          {formErrors.newpassword!=='' && formErrors.newpassword!==undefined && (<Form.Text className='error text-danger'>{formErrors.newpassword}</Form.Text>)}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="form-group" controlId="">
                          <Form.Label>Confirm New Password <span className="req">*</span></Form.Label>
                          <Form.Control type={confirmpasswordType} placeholder = "Enter Confirm New Password" name="confirmpassword" defaultValue={confirmpassword} onChange={onChange} />
                          <div onClick={toggleConfirmPassword} className="eye">{(confirmpasswordType==="password")?(<Icon icon="mdi:eye-off" />):<Icon icon="mdi:eye" />}</div>
                          {formErrors.confirmpassword!=='' && formErrors.confirmpassword!==undefined && (<Form.Text className='error text-danger'>{formErrors.confirmpassword}</Form.Text>)}
                          {passwordfailmessage!==''?(<Form.Text className='error text-danger'>{passwordfailmessage}</Form.Text>):''}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <div className='button-group mt-3 text-end'>
                          <Button variant="primary" className="btn btn-lg btn-primary me-0" onClick={onSubmit} >Update Password </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default ChangePassword;
