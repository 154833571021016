import React,{useEffect} from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';
import {useCookies,Cookies} from 'react-cookie';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { postOrderFail } from '../services/serviceapi';

const OrderFail = () => {
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO',"COUPONINFO","GUESTINFO","SHIPPINGINFO"]);
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

  const getOrderFail = ()=>{
    postOrderFail(id)
    .then(response => {
      if(response.status=== 200)
      {
        console.log(response,'response');
      }
    },)
  }

  useEffect(() => {
    getOrderFail()
  },[]);

  return (
    <div>
        <Header />
        <section className="section header-nxt order-success">
          <Container fluid="md">
            <Row>            
              <Col lg={12}>
                <div className='orderbox'>
                    <div className='success-icon text-danger'>
                      <Icon icon="mdi-close-circle" />
                    </div>
                    <h3 className='text-danger mb-4'>Your transcation is failed try again thanks.</h3>
                    <Link className="btn btn-lg btn-primary"  to="/">Back to Home</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

    </div>
  );
};

export default OrderFail;
