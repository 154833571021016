import React,{useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Icon } from '@iconify/react';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { postOtpValidate,postResendOTP } from "../services/serviceapi";

const Otp = () => {
  const [otp,setOtp] = useState("");
  const defData = {otp:''};
  const [formErrors,formError] = useState(defData);
  const queryParams = new URLSearchParams(window.location.search)
  const mobile = queryParams.get('mob');
  const [responseSuccess,respSuccess] = useState("");
  const [invalidotp,setInvalidOtp] =useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    let path = `/login`; 
    navigate(path);
  }
  const onChange = (e) => {
    formError('');
    const {value} = e.target;
    const re =/^[0-9]*$/;
    if (value === "" || re.test(value)) {
      setOtp(e.target.value)
    }
  }
  const onSubmit = (e) => {
    console.log(mobile,"mobile")
    const error = {}
    if(otp==='' )
    { 
      setInvalidOtp('');
      error.otp = 'Enter otp'
    }
    formError(error)

    if(Object.keys(error).length === 0)
    {
      postOtpValidate(mobile,otp)
      .then(response => {
        if(response.status=== 200)
        {
          respSuccess(response.message);
          handleShow()
        }
        else
        {
          setInvalidOtp(response.message)
        }
      },)
    } 
  }
  const resendOTP = (mobile)=>{
    setOtp('');
    setInvalidOtp("");
    postResendOTP(mobile,"register_otp")
    .then(response => {
      if(response.status=== 200)
      {
        // setOtp(response.otp);
        // respSuccess(response.message);
        // handleShow()
        // let path = `/confirmpassword?mob_num=${mobile}`; 
        // navigate(path);
      }
    },)
  }

  return (
    <div>
        <Header />
        <section className="section header-nxt otp-page">
          <Container fluid="md">
            <Row>            
              <Col lg={4} className="offset-lg-4">
                <div className="login-cont">
                  <h3>OTP</h3>
                  <Form className="mt-4">

                    <Form.Group className="form-group mb-3" controlId="formBasicEmail">
                      <Form.Label>Enter OTP Number <span className="req">*</span></Form.Label>
                      <Form.Control onChange={onChange} value={otp} autoComplete="off" maxLength={4} placeholder="" />
                      {formErrors.otp!=='' && formErrors.otp!==undefined && (<Form.Text className='error text-danger'>{formErrors.otp}</Form.Text>)}
                      {invalidotp?<Form.Text className='error text-danger'>{invalidotp}</Form.Text>:''}
                    </Form.Group>
                    <div className="forgot-row mt-2 mb-3">
                      <Link to={`/Otp?mob=${mobile}`} onClick={(e)=>resendOTP(mobile)} className="fwd-password resend-otp"><Icon icon="solar:refresh-linear" /> Resend OTP</Link>
                    </div>
                    <Button variant="primary" className="btn-lg w-100" onClick={onSubmit} >Submit</Button>                    
                  </Form>
                  <p className="reg-text">Back to <Link to="/register">Sign Up?</Link></p>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
        <Modal show={show} onHide={handleClose} size="md" className="modal-alert-option">
          <Modal.Header closeButton>
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              {responseSuccess}
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
};

export default Otp;