import React,{useEffect,useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {getProductDetail,postCartCount,postCartSave } from "../services/serviceapi";
import {getdiscount} from "../Pages/ProductList";
import {useCookies} from 'react-cookie';
import { v4 as uuid } from 'uuid';
import Toast from 'react-bootstrap/Toast';

const ProductDetailInfo = (params) => {
  const [showA, setShowA] = useState(false);
  const [isFlag, setFlag] = useState((params.flag !==undefined)?true:false);
  const toggleShowA = () => setShowA(!showA);
  const [productId, setProductId] = useState();
  const [productdetail, setProductDetail] = useState([]);
  // const [userStatus, setUserStatus ] = useState(1);
  const queryParams = new URLSearchParams(window.location.search)
  //const idParam = queryParams.get('id');
  // const cookies = useCookies();
  const [cookies, setCookie] = useCookies(['SESSIONINFO']);
  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

  const [responseSuccess,respSuccess] = useState('');
  const [responseCount,respCount] = useState(0);
  
  
  const getDetails = (idParam)=>{    
    
    getProductDetail(idParam)
    .then(response => {
      if(response.status=== 200 && response.product_details)
      {
        //console.log('response.product_details',response.product_details);
        setProductDetail(response.product_details);
        if(params.callFn !==undefined)
        {
          params.callFn(response,'');
        }
      }
      else if(response.status=== 400){
        <div>
          <h1>404 Error</h1>
          <h1>Page Not Found</h1>
        </div>
      }
    })
    // return [productdetail];
  }

  useEffect(() => {
    console.log('params',params);
    let idParam = params.idparams.product_name.split(' ').join('-');
    console.log('idParam',idParam);
    setProductId(idParam);
    getDetails(idParam);
    cartcount(id)
  // },[productdetail]);
  },[]);
  
  if(params!== undefined && params.idparams !== undefined){
    let idParam = params.idparams.product_name.split(' ').join('-');
    if(idParam !== productId) {
      setProductId(idParam);
      getDetails(idParam);
    }
  }
  
  const cartcount = (id)=>{
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if (cookieData.sessId === undefined || cookieData.sessId ==='')
      {
        const unique_id = uuid();
        // console.log(unique_id,'uuid');
        setCookie('SESSIONINFO', JSON.stringify(unique_id));
        cookieData.sessId = unique_id;
        
      }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartCount(id)
        .then(response => {
          if(response.status=== 200)
          {
            respCount(response.quantity_list);
            // setUserStatus(response.user_status);
          }
        },)
      }
    }
    else
    {
      postCartCount(id)
      .then(response => {
        if(response.status === 200)
        {
          respCount(response.quantity_list);
          // setUserStatus(response.user_status);
        }
      },)
    }
  }
  const handleOpen = (value) => {
    let p_id = value.product_id;
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO; 
      // if (cookieData.sessId === undefined || cookieData.sessId ==='')
      // {
      //   const unique_id = uuid();
      //   console.log(unique_id,'uuid');
      //   setCookie('SESSIONINFO', JSON.stringify(unique_id));
      //   cookieData.sessId = unique_id;
      // }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        // console.log(cookieData.sessId,'cookieData.sessId');
        postCartSave(id,p_id)
        .then(response => {
          if(response.status=== 200)
          {
            respSuccess(response.message);
            toggleShowA()

            cartcount(id)
          }
        },)
      }
    }
    else
    {
      postCartSave(id,p_id)
      .then(response => {
        if(response.status=== 200)
        {
          respSuccess(response.message);
          toggleShowA()

          cartcount(id)
        }
      },)
    }
    // if(params.callFn !==undefined)
    // {
    //   params.getCount(responseCount);
    // }
  }
  if(params.callFn !==undefined) {
    params.getCount(responseCount);
  }
    
	return (		
		<section className="section">
        <Toast show={showA} onClose={toggleShowA} className='toast-success' delay={1500} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">item added successfully</strong>
          </Toast.Header>
        </Toast>
          <Container fluid="md">
            <Row>            
              <Col lg={7} md={7} className="order-1">
                <div className="product-detail">
                  <h6 className="lead text-primary">Why Choose {productdetail.product_name} ?</h6>
                  <h2 className="text-capitalize fw-600">{productdetail.product_slogan?productdetail.product_slogan:''}</h2>
                  <ul className="prod_features" dangerouslySetInnerHTML={{__html:productdetail.product_description}}>
                  </ul>
                  <div className="price">
                    <h4>Selling Price<br/><strong> ₹ {productdetail.selling_price!==null?productdetail.selling_price:productdetail.actual_price}</strong>{productdetail.selling_price!==null?<strike>{productdetail.actual_price}</strike>:''}</h4>
                    {/* <p className="save"><span>save 25%</span></p> */}{productdetail.selling_price!==null?getdiscount(productdetail):''}
                  </div>
                  <div className="btn-list">
                    <Button variant="primary" size="lg" onClick = {(e) => 
                      handleOpen(productdetail)}>Add to Cart</Button>{' '}
                  </div>
                </div>
              </Col>
              <Col lg={5} md={5} className="order-sm-1">
                <div className="prod_detail_slider">
                  <img className="d-block w-100" src={productdetail.product_image} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
	);
};

export default ProductDetailInfo;
