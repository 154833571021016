import React,{useState,useEffect} from "react";
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { postforgotpassword } from "../services/serviceapi";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const ForgotPassword = () => {
  const [mobilenumber,setMobile] = useState("");
  const [responseerror,respError] = useState("");
  const defData = {mobilenumber:''};
  const [formErrors,formError] = useState(defData);
  const navigate = useNavigate();


  function isValidMobileNumber(mobilenumber) {
    return /^[6-9][0-9]{9}$/i.test(mobilenumber);
  }

  const onChange = (e) => {
    respError('');
    formError('');
    const eTarget = e.target;
    setMobile(eTarget.value)
  }
  const onSubmit = (e) => {
    // console.log('cookies',cookies);
    respError('');
    const error = {}
    if(mobilenumber==='' || mobilenumber===null) error.mobilenumber = 'Enter Mobile number'
    else if(mobilenumber!=="" && !isValidMobileNumber(mobilenumber)) 
      {
        error.mobilenumber = ' Please enter valid mobile number';
      }
    formError(error)
    if(Object.keys(error).length === 0){
      postforgotpassword(mobilenumber,)
      .then(response => {
        // console.log(response.user_id,'response');
        if(response.status=== 200)
        {
          // console.log('response',response);
          // if(cookies.LOGGEDINFO!==undefined||cookies.LOGGEDINFO!=='')
          // {
          //   // console.log('sdsdsd');
          // setCookie('LOGGEDINFO', JSON.stringify({user_id:response.user_id}));
          let path = `/forgot-otp?mob_num=${mobilenumber}`; 
          navigate(path);
          // }
        }
        else
        {
          respError(response.message);
        }
    },)
    }
  }
  useEffect(() => {
  
  },[mobilenumber]);
  return (
    <div>
        <Header />
        <section className="section header-nxt">
          <Container fluid="md">
            <Row>            
              <Col lg={4} className="offset-lg-4">
                <div className="login-cont">
                  <h3>Forgot Password</h3>
                  <Form className="mt-4">

                    <Form.Group className="form-group mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile Number <span className="req">*</span></Form.Label>
                      <Form.Control type="text" onChange={onChange} maxLength="10" placeholder="Enter Mobile Number" />
                      {formErrors.mobilenumber!=='' && formErrors.mobilenumber!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobilenumber}</Form.Text>)}
                      {responseerror?<Form.Text className='error text-danger'>{responseerror}</Form.Text>:''}
                    </Form.Group>
                    
                    <Form.Group className="form-group mb-3"></Form.Group>
                    <Button variant="primary" className="btn-lg w-100" onClick={onSubmit}>Reset Password</Button>
                  </Form>
                  <p className="reg-text">Back to <Link to="/login">Sign In?</Link></p>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
    </div>
  );
};

export default ForgotPassword;
