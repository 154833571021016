import React,{useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

const ServiceFeatures = () => {
  const [isLoading,setLoading] = useState(false);

  const getServiceFeatures = ()=>{
    return <Row>
    <Col lg={3} md={6}>
      <div className="site-features">
        <div className="icon_holder"><img src="assets/images/icon-free-shipping.svg" alt="" /></div>
        <h3 className="features_title">Free Shipping</h3>
        <p>Free shipping on all order</p>
      </div>
    </Col>
    <Col lg={3} md={6}>
      <div className="site-features">
        <div className="icon_holder"><img src="assets/images/icon-support.svg" alt="" /></div>
        <h3 className="features_title">Support 24/7</h3>
        <p>Free shipping on all order</p>
      </div>
    </Col>
    <Col lg={3} md={6}>
      <div className="site-features">
        <div className="icon_holder"><img src="assets/images/icon-money-return.svg" alt="" /></div>
        <h3 className="features_title">100% Money Back</h3>
        <p>Free shipping on all order</p>
      </div>
    </Col>
    <Col lg={3} md={6}>
      <div className="site-features">
        <div className="icon_holder"><img src="assets/images/icon-discount.svg" alt="" /></div>
        <h3 className="features_title">Order Discount</h3>
        <p>Free shipping on all order</p>
      </div>
    </Col>
  </Row>
  }
  const getLoaderList = ()=>{
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return <Row className="features-skeleton">
    <Col lg={3} xs={12}>
      <Row>
        <Col lg={4} xs={4}>
          <div className="product-skeleton skeleton thumb"></div>
        </Col>
        <Col lg={8} xs={8}>
          <div className="product-skeleton content">
            <div className="headingskl w90 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
          </div>
        </Col>
      </Row>
    </Col>
    <Col lg={3} xs={12}>
      <Row>
        <Col lg={4} xs={4}>
          <div className="product-skeleton skeleton thumb"></div>
        </Col>
        <Col lg={8} xs={8}>
          <div className="product-skeleton content">
            <div className="headingskl w90 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
          </div>
        </Col>
      </Row>
    </Col>
    <Col lg={3} xs={12}>
      <Row>
        <Col lg={4} xs={4}>
          <div className="product-skeleton skeleton thumb"></div>
        </Col>
        <Col lg={8} xs={8}>
          <div className="product-skeleton content">
            <div className="headingskl w90 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
          </div>
        </Col>
      </Row>
    </Col>
    <Col lg={3} xs={12}>
      <Row>
        <Col lg={4} xs={4}>
          <div className="product-skeleton skeleton thumb"></div>
        </Col>
        <Col lg={8} xs={8}>
          <div className="product-skeleton content">
            <div className="headingskl w90 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
  }
	return (		
		<section className="section features">
          <Container fluid="md">
            {isLoading===false?getLoaderList():getServiceFeatures()}
            {/* <Row>
              <Col lg={3} md={6}>
                <div className="site-features">
                  <div className="icon_holder"><img src="assets/images/icon-free-shipping.svg" alt="" /></div>
                  <h3 className="features_title">Free Shipping</h3>
                  <p>Free shipping on all order</p>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="site-features">
                  <div className="icon_holder"><img src="assets/images/icon-support.svg" alt="" /></div>
                  <h3 className="features_title">Support 24/7</h3>
                  <p>Free shipping on all order</p>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="site-features">
                  <div className="icon_holder"><img src="assets/images/icon-money-return.svg" alt="" /></div>
                  <h3 className="features_title">100% Money Back</h3>
                  <p>Free shipping on all order</p>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="site-features">
                  <div className="icon_holder"><img src="assets/images/icon-discount.svg" alt="" /></div>
                  <h3 className="features_title">Order Discount</h3>
                  <p>Free shipping on all order</p>
                </div>
              </Col>
            </Row> */}
          </Container>
        </section>
	);
};

export default ServiceFeatures;
