import React,{useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { postforgotpasswordotp,postResendOTP } from "../services/serviceapi";

const ForgotOtp = () => { 
  const queryParams = new URLSearchParams(window.location.search)
  const mobile = queryParams.get('mob_num');
  console.log(mobile,'mobile');
  const [otp,setOtp] = useState("");
  const [invalidotp,setInvalidOtp] =useState("");
  const navigate = useNavigate();

  const defData = {otp:''};
  const [formErrors,formError] = useState(defData); 

  const onChange = (e) => {
    formError('');
    setInvalidOtp('');
    const {value} = e.target;
    const re =/^[0-9]*$/;
    if (value === "" || re.test(value)) {
      setOtp(e.target.value)
    }
  }
  const onSubmit = (e) => {
    const error = {}
    if(otp==='' )
    { 
      setInvalidOtp('');
      error.otp = 'Enter otp'
    }
    formError(error)

    if(Object.keys(error).length === 0)
    {
      postforgotpasswordotp(mobile,otp)
      .then(response => {
        if(response.status=== 200)
        {
          // respSuccess(response.message);
          // handleShow()
          let path = `/confirmpassword?mob_num=${mobile}`; 
          navigate(path);
        }
        else
        {
          setInvalidOtp(response.message)
        }
      },)
    } 
  }

  const resendOTP = ()=>{
    setInvalidOtp('');
    postResendOTP(mobile,"forgot_otp")
    .then(response => {
      if(response.status=== 200)
      {
        // setOtp(response.otp);
        // respSuccess(response.message);
        // handleShow()
        // let path = `/confirmpassword?mob_num=${mobile}`; 
        // navigate(path);
      }
    },)
  }

  return (
    <div>
        <Header />
        <section className="section header-nxt otp-page">
          <Container fluid="md">
            <Row>            
              <Col lg={4} className="offset-lg-4">
                <div className="login-cont">
                  <h3>OTP</h3>
                  <Form className="mt-4">

                    <Form.Group className="form-group mb-3" controlId="formBasicEmail">
                      <Form.Label>Enter OTP Number <span className="req">*</span></Form.Label>
                      <Form.Control onChange={onChange} value={otp} autoComplete="off" maxLength={4} placeholder="" />
                      {formErrors.otp!=='' && formErrors.otp!==undefined && (<Form.Text className='error text-danger'>{formErrors.otp}</Form.Text>)}
                      {invalidotp?<Form.Text className='error text-danger'>{invalidotp}</Form.Text>:''}
                      {/* <Form.Text className="error text-danger">Invalid OTP</Form.Text>                      */}
                    </Form.Group>
                    <div className="forgot-row mt-2 mb-3">
                      <Link to={`/forgot-otp?mob_num=${mobile}`} onClick={(e)=>resendOTP()} className="fwd-password resend-otp"><Icon icon="solar:refresh-linear" /> Resend OTP</Link>
                    </div>
                    <Button variant="primary" className="btn-lg w-100" onClick={onSubmit}>Submit</Button>                  
                  </Form>
                  <p className="reg-text">Back to <Link to="/login">Sign In?</Link></p>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
    </div>
  );
};

export default ForgotOtp;
