import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { Link } from "react-router-dom";

const InnerBanner = (params) => {
return (
	<section className="inner-banner">
		<Container fluid="md">
			<Row>
				<Col lg={6} className="offset-lg-3">
					<div className="inner-cont">
						<h1>{(params.data !==undefined)?params.data.product_name:''}</h1>
						<Breadcrumb>
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item active>Product Detail</Breadcrumb.Item>
						</Breadcrumb>			
						{/* <p>{params.data.product_description}</p> */}

					</div>
				</Col>
			</Row>
		</Container>
	</section>
);
};

export default InnerBanner;
