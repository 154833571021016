import React,{useEffect,useState} from "react";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import HomeBanner from "../Components/HomeBanner";
import BeetoMaltPlus from "../Components/BeetoMaltPlus";
import FruitoMalt from "../Components/FruitoMalt";
import BeetoMalt from "../Components/BeetoMalt";
import Testimonials from "../Components/Testimonials";
import ServiceFeatures from "../Components/ServiceFeatures";
import { postCartCount } from "../services/serviceapi";
import {useCookies} from 'react-cookie';
import { v4 as uuid } from 'uuid';

const Home = () => {
  const [cookies, setCookie] = useCookies(['SESSIONINFO']);
  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

  const cartcount = (id)=>{
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if (cookieData.sessId === undefined || cookieData.sessId ==='')
      {
        const unique_id = uuid();
        // console.log(unique_id,'uuid');
        setCookie('SESSIONINFO', JSON.stringify(unique_id));
        cookieData.sessId = unique_id;
        
      }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartCount(id)
        .then(response => {
          if(response.status=== 200)
          {
            respCount(response.quantity_list);
          }
        },)
      }
    }
    else
    {
      postCartCount(id)
      .then(response => {
        if(response.status === 200)
        {
          respCount(response.quantity_list);
        }
      },)
    }
  }
  
  const [responseCount,respCount] = useState(0);
  const getCart = (data) =>{
    cartcount(id);
  }
  
  return (
    <div>
        <Header cartCount={responseCount}/>
        
        <HomeBanner />

        <ServiceFeatures />

        
        <BeetoMalt callFn={getCart}/>

        <FruitoMalt callFn={getCart} />

        <BeetoMaltPlus callFn={getCart}/>

        <Testimonials />

        <Footer />
    </div>
  );
};

export default Home;
