import React,{useEffect, useState} from "react";
import { Link,useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';
import { postRegister } from "../services/serviceapi";
import Modal from 'react-bootstrap/Modal';
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";


const Register = () => {
const [mobilenumber,setMobile] = useState("");
const [password, setPwd] = useState("");
const defData = {email:'',password:''};
const [formErrors,formError] = useState(defData);
const [responseError,respError] = useState('');
const [responseSuccess,respSuccess] = useState('');

// const [show, setShow] = useState(false);
const navigate = useNavigate();
// const handleClose = () => {
//   // setPwd('');
//   setShow(false);
//   let path = `/Otp`; 
//   navigate(path);
// }
useEffect(() => {
  console.log('mobile',mobilenumber)
},[mobilenumber,password]);
// const handleShow = () => setShow(true);
// const re=/\S+@\S+\.\S+/;
const [passwordType, setPasswordType] = useState("password");

const togglePassword =()=>{
  if(passwordType==="password")
  {
   setPasswordType("text");
  }else{
    setPasswordType("password");
  }
}

function isValidMobileNumber(mobilenumber) {
  return /^[6-9][0-9]{9}$/i.test(mobilenumber);
}
function isCheckPassword(str)
{
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  return re.test(str);
}

const onChange = (e) => {
   const eTarget = e.target;
  //  console.log(eTarget.value,"value")
   switch(eTarget.name) {
   case 'mobilenumber':
    respError('');
    setMobile(eTarget.value)
   break;
   
   case 'password':
    respError('');
    setPwd(eTarget.value)
   break;
   
   default:
   break;
  }
}
 
const onSubmit = (e) => {
  const error = {}
  if(mobilenumber==='' ) error.mobilenumber = 'Enter Mobile number'
  else if(mobilenumber!=="" && !isValidMobileNumber(mobilenumber)) 
    {
      error.mobilenumber = ' Please enter valid mobile number';
    }
 
  if(password==='') error.password = 'Enter Password'
  else if(password!=="" && !isCheckPassword(password))
    {
      error.password = ' Password should have atleast 6 characters includes atleast one symbol, caps and numeric';
    }
  if(Object.keys(error).length === 0){
    postRegister(mobilenumber,password)
    .then(response => {
      if(response.status=== 200)
      {
        respSuccess(response.message);
        let path = `/Otp?mob=${mobilenumber}`; 
        navigate(path);
      }
      else
      {
        // console.log('response',response);
        respError(response.message);
      }
  },)
  } 
  // console.log('error',error);
  formError(error)
  }

  const getinputform = () => {
    
   return <Form className="mt-4" >
    <Form.Group className="form-group mb-3" controlId="formBasicEmail">
      <Form.Label>Mobile number <span className="req">*</span></Form.Label>
      <Form.Control type="text" maxLength = "10" name="mobilenumber" autoComplete="off" defaultValue={mobilenumber} onChange={onChange} placeholder="Enter Mobile number" />
      {formErrors.mobilenumber!=='' && formErrors.mobilenumber!==undefined && (<Form.Text className='error text-danger'>{formErrors.mobilenumber}</Form.Text>)}
    </Form.Group>

    <Form.Group className="form-group mb-4" controlId="formBasicPassword">
      <Form.Label>Password <span className="req">*</span></Form.Label>
      <Form.Control type={passwordType} maxLength="20" name="password" defaultValue={password} placeholder="Password" onChange={onChange} />
      <div onClick={togglePassword} className="eye">{(passwordType==="password")?(<Icon icon="mdi:eye-off" />):<Icon icon="mdi:eye" />}</div>
      {formErrors.password!=='' && formErrors.password!==undefined && (<Form.Text className='error text-danger'>{formErrors.password}</Form.Text>)}
      {responseError !=='' && responseError !==undefined && (<Form.Text className='error text-danger'>{responseError}</Form.Text>)}
    </Form.Group>

    <Form.Group className="form-group mb-3"></Form.Group>
    <Button variant="primary" className="btn-lg w-100" onClick={onSubmit} >Submit</Button>
  </Form>
  }

  return (
    <div>
        <Header />
        <section className="section header-nxt">
          <Container fluid="md">
            <Row>            
              <Col lg={4} className="offset-lg-4">
                <div className="login-cont">
                  <h3>Sign Up</h3>
                  {getinputform()}
                  <p className="reg-text">Already have an account <Link to="/Login">Sign In</Link></p>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />

        {/* <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='form-style1'> 
            <Row>
              {responseSuccess}
            </Row>              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal> */}
    </div>
  );
};

export default Register;
