import React,{ useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import InnerBanner from "../Components/InnerBanner";
import ProductDetailInfo from "../Components/ProductDetailInfo";
import Faq from "../Components/Faq";
import { useLocation } from 'react-router-dom';
import Promotion from "../Components/Promotion";

const ProductDetail = () => {
  const [faq, setFAQ] = useState([]);
  const [productDetail, setProductdetail] = useState([]);
  const [cartCount,setCount] = useState(0);
  const queryParams = new URLSearchParams(window.location.search)
  const idParam = queryParams.get('id');
  const { state } = useLocation(); 

  const { value } = state || {}; 
  //console.log('product',value);
  const getFaq = (data) =>{
    setFAQ(data.faq_list);
    setProductdetail(data.product_details);
  }
  const getCount = (count) =>{
    setCount(count);
    // console.log("count123",count);
  }
  useEffect(() => {
     //console.log("count123",idParam);
  },[]);
  // console.log('productDetail',productDetail);
  
  return (
    <div>
        <Header cartCount={cartCount} />

        <InnerBanner data={productDetail} />

        <ProductDetailInfo flag={true} idparams={value} getCount={getCount} callFn={getFaq}/>        

        <Faq faqdata={faq} />

        <Promotion data={productDetail} />
                   
        <Footer />

      {/* Loader Start */}
      {/* <div className="loader-wrapper">
        <div className="loader">
          <img src="assets/images/logo.png" className="img-fluid" alt="" title="" />
        </div>
      </div> */}
      {/* Loader End */}

    </div>
  );
};

export default ProductDetail;
