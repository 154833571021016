import React,{useState,useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getTermsCondition } from "../services/serviceapi";

const TermsCondition = () => {
  const [isLoading,setLoading] = useState(false);
  const [terms_condition, setTermsCondition] = useState({});
  const getTermsConditionDetails = (page_url)=>{
    getTermsCondition(page_url)
    .then(response => {
      if(response.status === 200)
      {
        setTermsCondition(response.cms_details);
      }
    },)
  }
  const getLoaderList = ()=>{
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return <Row>
      <Col lg={12}>
        <div className="product-skeleton skeleton-content">
          <div className="headingskl skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
        </div>
      </Col>
    </Row>
  }
  const getDescription = ()=>{
    return <Row>            
      <Col lg={12}>
        <div className="about-content" dangerouslySetInnerHTML={{__html:terms_condition.page_description}}>
        </div>       
      </Col>
    </Row>
  }
  useEffect(() => {
    getTermsConditionDetails("terms_condition")
  },[]);
  return (
    <div>
        <Header />
        
        <section className="inner-banner">
          <Container fluid="md">
            <Row>
              <Col lg={6} className="offset-lg-3">
                <div className="inner-cont">
                  <h1>{terms_condition.page_title}</h1>
                  <Breadcrumb>
                    <title>{terms_condition.meta_title}</title>
                    <meta name="description" content={terms_condition.meta_content}/>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>{terms_condition.page_name}</Breadcrumb.Item>
                  </Breadcrumb>			
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <Container fluid="md">
            {isLoading===false?getLoaderList():getDescription()}
            {/* <Row>            
              <Col lg={12}>
                <div className="about-content" dangerouslySetInnerHTML={{__html:terms_condition.page_description}}>
                </div>       
              </Col>
            </Row> */}
          </Container>
        </section>

        <Footer />
    </div>
  );
};

export default TermsCondition;
