import React,{useState,useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getReturnPolicy } from "../services/serviceapi";

const ReturnPolicy = () => {
  const [isLoading,setLoading] = useState(false);
  const [return_policy, setReturnDetails] = useState({});
  const getReturnPolicyDetails = (page_url)=>{
    getReturnPolicy(page_url)
    .then(response => {
      if(response.status === 200)
      {
        // console.log(response,'retrun');
        setReturnDetails(response.cms_details);
      }
    },)
  }
  const getLoaderList = ()=>{
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return <Row>
      <Col lg={12}>
        <div className="product-skeleton skeleton-content">
          <div className="headingskl skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w100 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
        </div>
      </Col>
    </Row>
  }
  const getDescription = ()=>{
    return <Row>
      <Col lg={12}>
        <div className="about-content" dangerouslySetInnerHTML={{__html:return_policy.page_description}}>
        </div>     
      </Col>
    </Row>
  }
  useEffect(() => {
    getReturnPolicyDetails("return_policy")
  },[]);
  return (
    <div>
        <Header />
        
        <section className="inner-banner">
          <Container fluid="md">
            <Row>
              <Col lg={6} className="offset-lg-3">
                <div className="inner-cont">
                  <h1>{return_policy.page_title}</h1>
                  <Breadcrumb>
                    <title>{return_policy.meta_title}</title>
                    <meta name="description" content={return_policy.meta_content}/>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>{return_policy.page_name}</Breadcrumb.Item>
                  </Breadcrumb>		
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section">
          <Container fluid="md">
            {isLoading===false?getLoaderList():getDescription()}
            {/* <Row>
              <Col lg={12}>
                <div className="about-content" dangerouslySetInnerHTML={{__html:return_policy.page_description}}>
                </div>     
              </Col>
            </Row> */}
          </Container>
        </section>

        <Footer />
    </div>
  );
};

export default ReturnPolicy;
