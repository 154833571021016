import React,{useEffect,useState} from 'react';
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon } from '@iconify/react';
import Card from 'react-bootstrap/Card';
import { postOrderConfirm,postOrderListing,postOrderCheckOut } from "../services/serviceapi";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {useCookies} from 'react-cookie';
// import { useCallback } from "react";
import useRazorpay from "react-razorpay";


const ConfirmOrder = () => {
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search)
  const ship_id = queryParams.get('s_Id');
  const [billingAddress,setBillingAdd] = useState({});
  const [shippingAddress,setShippingAdd] = useState({});
  const [cartList,setCartList] = useState({});
  const [shipCost,setShipCost] = useState(0);
  const [coupon_code,setCouponCode] = useState('');
  const [coupon_amount,setCouponAmount] = useState(0);
  const [load,setLoad] = useState(0);
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO','COUPONINFO','GUESTINFO']);
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:cookies.GUESTINFO.user_id;
  const [editId,setEditId] = useState(id);
  

  const getOrderCheckout = ()=>{
    postOrderCheckOut(id,ship_id)
    .then(response => {
      console.log('billing adress',response);
      if(response)
      {
        setBillingAdd(response.billing_address);
        setShippingAdd(response.shipping_addrss);
        setCartList(response.cart_list.cart_list);
        setShipCost(response.shipping_cost);
      }
    },)
  }

  useEffect(() => {
    getOrderCheckout();
    const cookieData = {};
    cookieData.couponcode = cookies.COUPONINFO;
    if (cookieData.couponcode  !== undefined)
    {
      setCouponCode(cookieData.couponcode.coupon_code);
      setCouponAmount(cookieData.couponcode.amount);
    }
    else
    {
      setCouponCode('');
      setCouponAmount('');
    }


    console.log('setload',load);


    
	},[]);

  const getCartList = ()=> {
    const content=[];
    if(cartList.length > 0){
      cartList.map((value,index) => (
      content.push(<Row>
        <Col lg={6} xs={4}>
          <div className="cart-content product">
            <div className="thumb">
              <img src={value.product_image} className="img-fluid" alt="" title=""></img>
            </div>
            <div className="info">
              <h4>{value.product_name} </h4>
              <p>{value.variant_name} {value.unit_name}</p>
            </div>
          </div>
        </Col>
        <Col lg={6} xs={8}>
          <Row>
            <Col xs={12} className="mob-prodcut">
              {/* <h4>Beet o malt plus - 250 G </h4> */}
            </Col>
            <Col lg={4} xs={12} className="col">
              <div className="cart-content price">
                <p>₹{value.product_price}</p>
              </div>
            </Col>
            <Col lg={4} xs={12} className="col">
              <div className="cart-content">
                <p>Quantity : {value.quantity}</p>
              </div>
            </Col>
            <Col lg={4} xs={12} className="col">
              <div className="cart-content total">
              <p>₹{cartList[index].quantity * cartList[index].product_price}</p>
              </div>
            </Col>
          </Row>
        </Col>                
      </Row>)
      ))
    }
    return content;
  }
  const getSubTotal = ()=>{
    let subTotal = 0;
    if (cartList.length > 0)
    {
      cartList.map((value,index) => (
        subTotal = subTotal + cartList[index].product_price * cartList[index].quantity
      ))
      return subTotal;
    }
  }
  const getAmountPayable = ()=>{
    let amount = 0;
    amount = Math.round(getSubTotal() + parseInt(shipCost) - parseInt(coupon_amount));
    return amount;
  }
  // const fetchConfirmOrder = (coupon_amount,bill_id,ship_id,delivery_charge) => {
  //   postOrderConfirm(id,coupon_code,bill_id,ship_id,delivery_charge)
  //   .then(response => {
  //     if(response.status === 200)
  //     {
  //       // console.log(response,'response');
  //       // fetchRPay()
  //     }
  //   },)
  // }
  const fetchRPay = (getAmountPayable,coupon_amount,bill_id,ship_id,delivery_charge)=> {
    var options = {
      //demo
      /* key : "rzp_test_rYpEiH9fyB50L3",
      key_secret : "m1hAEe2rQthHJiXA1ToHlWmB", */
      
      //live
      key : "rzp_live_wtQwLQ7wfHDexp",
      key_secret : "wtjNkjDWg8XfTQ8Ov2zQpixi",

      amount : getAmountPayable*100,
      currency : "INR",
      name : "Mayilon Foods",
      description : "Testing",

      
      handler : function(Resp)
      {
        setLoad(0);
        {load===0?getCOrder():getPaymentLoader()}
         console.log(load,'load');
        postOrderConfirm(editId,coupon_code,bill_id,ship_id,delivery_charge,Resp.razorpay_payment_id)
        .then(response => {
          if(response.status !== '')
          {
            let path = `/ordersuccess?iv_Id=${response.invoice_id}`
            navigate(path);
            window.location.reload();
          }
        },)
      },
      prefill: {
        name : " ",
        email : "a@g.com",
        contact :"9361250732"
      },
      notes:{
        address:"Razorpay corporate office"
      },
      theme:{
        color:"#3399cc"
      }
    };
    var pay = new window.Razorpay(options);
    return pay.open();

   // const newTab = window.open("", "_blank");

    /* document.write('<html><head><title>Razorpay Payment</title></head><body>');
    document.write('<script src="https://checkout.razorpay.com/v1/checkout.js"></script>');
    document.write('<script>');
    document.write('var options = ' + JSON.stringify(options) + ';');
    document.write('options.modal = {');
    document.write('  ondismiss: function onDismissHandler() {');
   // document.write('    window.close()');
    document.write('    window.location.href = "/orderfail";'); // Redirect to the /checkout page
   // document.write('    window.location.href = "/checkout";'); // Change newTab to window
    //document.write('    window.location.href = "/checkout"');
    document.write('  }');
    document.write('};');
    document.write('var razorpay = new Razorpay(options);');

    document.write('razorpay.on("payment.success", function(response) {');
    // document.write('   console.log(Handle successful payment)');
    document.write('  console.log("Handle successful payment:", response);');
    document.write('  window.location.href = "/ordersuccess?iv_Id=" + response.invoice_id;');
    document.write('});');
    // document.write('  window.location.href = "/ordersuccess";');
    document.write('razorpay.open();');
    document.write('</script></body></html>'); */

    
    //return pay.open();
  }
  const fetchOrderConfirm = (coupon_amount,bill_id,ship_id,delivery_charge)=>{
    //setLoad(1);
    fetchRPay(getAmountPayable(),coupon_amount,bill_id,ship_id,delivery_charge)
  }
  const getPaymentLoader = ()=>{
    return <div class="loader-wrapper2" style="display:none">
    <div class="loader2">
      <img src="assets/images/logo.png" class="img-fluid" alt="" title="" />
    </div>
  </div>
  }
  useEffect(() => {
    const cookieData = {};
    cookieData.couponcode = cookies.COUPONINFO;
    localStorage.setItem('continue_order',1)
    localStorage.setItem('guest_continue',1)


    if (cookieData.couponcode  !== undefined)
    {
      setCouponCode(cookieData.couponcode.coupon_code);
      setCouponAmount(cookieData.couponcode.amount);
    }
    else
    {
      setCouponCode('');
      setCouponAmount('');
    }
    
  },[coupon_code,coupon_amount]);

  const getCOrder = ()=>{
    return <><Header />
    <section className="section header-nxt confirm-page">
      <Container fluid="md">
      <div className="main-heading3 icon-right">
        <h3>Order Confirmation</h3>
        <Link className='btn btn-primary btn-md' to="/checkout">Back</Link>
      </div>
        <Row>            
          <Col lg={8}>
            <Card className="card-style1">
              <Card.Header>
              Confirm Address
              </Card.Header>
              <Card.Body>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={6}>
                      <div className="address-box mb-0">
                        <div className='address-header'>
                          {/* <h5>Shipping Address</h5> */}
                          <h5>Billing Address</h5>

                        </div>
                        <h5>{shippingAddress.customer_name}</h5>
                        <p>{shippingAddress.customer_address} {shippingAddress.customer_address1}, {shippingAddress.city} - {shippingAddress.pincode}</p>
                      </div>       
                    </Col>
                    <Col lg={6}>
                      <div className="address-box mb-0">
                        <div className='address-header'>
                          {/* <h5>Billing Address</h5> */}
                          <h5>Shipping Address</h5>
                        </div>
                        <h5>{billingAddress.customer_name}</h5>
                        <p>{billingAddress.customer_address} {billingAddress.customer_address1}, {billingAddress.city} - {billingAddress.pincode}</p>
                      </div>       
                    </Col>
                  </Row>     
                </Col>                    
              </Row>
              </Card.Body>
            </Card>

            <Card className="card-style1 order-product mt-3">
              <Card.Header>
               Order Products
              </Card.Header>
              <Card.Body>
              <div className="cart-box">
                  <div className='cart-body'>
                    <div className="cart-row">
                      {getCartList()}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>   

          </Col>
          <Col lg={4}>
            <Card className="card cart mt-0">
              <Card.Header>
               Order summary
              </Card.Header>
              <Card.Body>
                <p className="desc">Shipping and additional costs are calculated based on values you have entered.</p>
                <div className="label-price mb-3">
                  <p>Order Subtotal  <span>₹{getSubTotal()}</span></p>
                  <p>Shipping and handling <span>₹{shipCost}</span></p>
                  {coupon_amount!==0||cookies.COUPONINFO!==undefined?<p>Coupon <span>₹{coupon_amount}</span></p>:''}
                  <p className="bold">Amount Payable <span>₹{getAmountPayable()}</span></p>
                </div>
                <Link className="btn btn-lg btn-primary w-100" onClick = {(e)=>fetchOrderConfirm(coupon_amount,billingAddress.customer_address_id,shippingAddress.customer_address_id,shipCost)}>Continue to Pay</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
    <Footer />
    </>
  }

  return (
    <div>
      {load===0?getCOrder():getPaymentLoader()}
    </div>
  );
};

export default ConfirmOrder;
