import React,{useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
// import {getDetails} from '../Components/ProductDetailInfo';
const Faq = (params) => {
  const [isLoading, setLoading] = useState(false);
  //console.log('par',params);
   
  const getfaqlist = ()=>{
    const content=[];
    (params.faqdata !==undefined) && params.faqdata.map((value,index) => (
      content.push(<Accordion.Item key={index} eventKey={value.product_faq_id}>
      <Accordion.Header>{value.question}</Accordion.Header>
      <Accordion.Body>
        {value.answer}
      </Accordion.Body>
    </Accordion.Item>)
    ))
    return content;
  }
  const getLoaderList = ()=>{
    const content=[];
    (params.faqdata !==undefined) && params.faqdata.map((value,index) => (
      content.push(<Row>            
        <Col lg={5}>                 
          <div className="product-skeleton">
            <div className="headingskl skeleton"></div>
            <div className="descskl w80 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="thumb-col3 w60 skeleton"></div>
          </div>                   
        </Col>
        <Col lg={7}>           
          <div className="product-skeleton">
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w70 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w70 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w70 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w70 skeleton"></div>
            <div className="descskl w100 skeleton"></div>
            <div className="descskl w70 skeleton"></div>
          </div>               
        </Col>
      </Row>)
    ))
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return content;
  }
	return (		
		<section className="section faq">
          <Container fluid="md">
            <Row>            
                <Col lg={5}>
                  <div className="mb-5 mobm5">
                    <h2 className="fw-800 text-primary fs-1">Frequently<br/>Asked Questions</h2>
                    <p>FAQ is a space on an ecommerce store where important information about a product is shared to clarify questions and uncertainties on the part of customers.</p>
                  </div>
                  <img src="../assets/images/faq.png" alt="" className="w-75 d-none d-lg-block"/>
                </Col>
                <Col lg={7}>
                  <div className="accordion-custom">
                    <Accordion defaultActiveKey="0" flush>
                      {isLoading===false?getLoaderList():(params.faqdata !=='')?getfaqlist():''}
                    </Accordion>
                  </div>
                </Col>
            </Row>
          </Container>
        </section> 
	);
};

export default Faq;
