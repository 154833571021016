import React,{useEffect,useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { getHomeProduct, postCartSave,postCartCount } from "../services/serviceapi";
import { getdiscount } from "../Pages/ProductList";
import {useCookies} from 'react-cookie';
import { v4 as uuid } from 'uuid';
import Toast from 'react-bootstrap/Toast';
import Header from "../Components/Header";


const BeetoMalt = (params) => {
  const [isLoading,setLoading] = useState(false);
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [pd,setPD] = useState("1");
  const [value, setProduct1] = useState([""]);
  const [cookies, setCookie] = useCookies(['SESSIONINFO']);
  const [responseSuccess,respSuccess] = useState('');
  const [responseCount,respCount] = useState(0);



  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;


  const getProduct = ()=>{
    getHomeProduct(pd)
    .then(response => {
      if(response.status=== 200)
      {
        setProduct1(response.product_details)
      }
    })
  }
  const cartcount = (id)=>{
    if(id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO; 
      if (cookieData.sessId === undefined || cookieData.sessId ==='')
      {
        const unique_id = uuid();
        setCookie('SESSIONINFO', JSON.stringify(unique_id));
        cookieData.sessId = unique_id;
      }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartCount(id)
        .then(response => {
          if(response.status=== 200)
          {
            respCount(response.quantity_list);
          }
        },)
      }
    }
    else
    {
      postCartCount(id)
      .then(response => {
        if(response.status === 200)
        {
          respCount(response.quantity_list);
        }
      },)
    }
  }
  const handleOpen = (value) => {
    let p_id = value.product_id;
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      // if (cookieData.sessId === undefined || cookieData.sessId ==='')
      // {
      //   const unique_id = uuid();
      //   console.log(unique_id,'uuid');
      //   setCookie('SESSIONINFO', JSON.stringify(unique_id));
      //   cookieData.sessId = unique_id;
      // }
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        // console.log(cookieData.sessId,'cookieData.sessId');
        postCartSave(id,p_id)
        .then(response => {
          if(response.status=== 200)
          {
            respSuccess(response.message);
            toggleShowA()

            cartcount(id)
          }
        },)
      }
    }
    else
    {
      postCartSave(id,p_id)
      .then(response => {
        if(response.status=== 200)
        {
          respSuccess(response.message);
          toggleShowA()

          cartcount(id)
        }
      },)
    }
    
  }
  if(params.callFn !==undefined)
  {
    params.callFn(responseCount);
  }
  useEffect(() => {
    getProduct()
    cartcount(id);
  },[]);
  
  const getProductDetails = ()=>{
    return <Row>
      <Col lg={8} md={7} className="order-1">
        <div className="product-detail home">
          <h2 className="prod-name">{value.product_name}</h2>
          <h5 className="prod-var">{value.variant_name} {value.unit_name}</h5>
          <ul className="prod_features"
          dangerouslySetInnerHTML={{__html:value.product_description}} >
            
          </ul>
          <div className="price">
            <h4>Selling Price<br/><strong> ₹ {value.selling_price!==null?value.selling_price:value.actual_price}</strong>{value.selling_price!==null?<strike>{value.actual_price}</strike>:''}</h4>
            {/* <p className="save"><span>save 25%</span></p> */}
            {value.selling_price!==null?getdiscount(value):''}
          </div>
          <div className="btn-list">
            <Link className="btn btn-outline-primary btn-lg mr-15" to={`/product-detail?#${pd}`.toLowerCase().split(' ').join('-')} state={{value}}>See More</Link>
            {/* <Link className="btn btn-primary btn-lg" to="/Cart">Add to Cart</Link> */}
            <Button variant="primary" size="lg" onClick = {(e) => 
              handleOpen(value)}>Add to Cart</Button>{' '}
          </div>
        </div>
      </Col>
      <Col lg={4} md={5} className="order-sm-1">
        <div className="product-detail-img">
          <Link to="/" className="prod_hero" data-aos="fade-down"><img className="d-block w-100" src={value.product_image} alt="" /></Link>
          <Link to="/" className="prod_hero_alt" data-aos="zoom-in" data-aos-delay="300"><img className="d-block w-100" src="/assets/images/beetroot.png" alt="" /></Link>
        </div>
      </Col>
    </Row>
  }

  const getLoaderList = ()=>{
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return <Row>
      <Col lg={7}>
        <div className="product-skeleton">
          <div className="headingskl skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
          <div className="descskl w60 skeleton"></div>
          <div className="descskl w70 skeleton"></div>
        </div>
      </Col>
      <Col lg={5}>
        <div className="product-image-skl skeleton">
          
        </div>
      </Col>
    </Row>
  }
  
	return (		
		<section className="section product">
        <Container fluid="md">
            {isLoading===false?getLoaderList():getProductDetails()}
            {/* <Row>
              <Col lg={8} md={7} className="order-1">
                <div className="product-detail home">
                  <h2 className="prod-name">{value.brand_name}</h2>
                  <h5 className="prod-var">{value.variant_name} {value.unit_name}</h5>
                  <ul className="prod_features"
                   dangerouslySetInnerHTML={{__html:value.product_description}} >
                    
                  </ul>
                  <div className="price">
                    <h4>Selling Price<br/><strong> ₹ {value.selling_price!==null?value.selling_price:value.actual_price}</strong>{value.selling_price!==null?<strike>{value.actual_price}</strike>:''}</h4>
                    {value.selling_price!==null?getdiscount(value):''}
                  </div>
                  <div className="btn-list">
                    <Link className="btn btn-outline-primary btn-lg mr-15" to={`/Product/Detail?id=${pd}`}>See More</Link>
                    <Button variant="primary" size="lg" onClick = {(e) => 
                      handleOpen(value)}>Add to Cart</Button>{' '}
                  </div>
                </div>
              </Col>
              <Col lg={4} md={5} className="order-sm-1">
                <div className="product-detail-img">
                  <Link to="/" className="prod_hero" data-aos="fade-down"><img className="d-block w-100" src={value.product_image} alt="" /></Link>
                  <Link to="/" className="prod_hero_alt" data-aos="zoom-in" data-aos-delay="300"><img className="d-block w-100" src="assets/images/beetroot.png" alt="" /></Link>
                </div>
              </Col>
            </Row> */}
            
          </Container>
          <Toast show={showA} onClose={toggleShowA} className='toast-success' delay={1500} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">item added successfully</strong>
          </Toast.Header>
        </Toast>
        </section>
	);
};

export default BeetoMalt;
