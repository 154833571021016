import React,{useEffect,useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Toast from 'react-bootstrap/Toast';
import { v4 as uuid } from 'uuid';
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {getAllProducts,postCartCount,postCartSave} from "../services/serviceapi";

export const getdiscount = (list)=>{
  let discount = 0;
  let discount_percent = 0;
  if(list.selling_price)
    {
      discount = list.actual_price - list.selling_price
      discount_percent = Math.round(discount / list.actual_price * 100)
    }
    else
    {
      list.actual_price = ''
    }
  return(<span className="save">save {discount_percent}%</span>);
}

const ProductList = () => {
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);
  const [product, setProduct] = useState([]);
  const [isLoading,setLoading] = useState(false);
  const [responseSuccess,respSuccess] = useState('');
  const [responseCount,respCount] = useState(0);
  const [cookies, setCookie] = useCookies(['SESSIONINFO','LOGGEDINFO']);
  // const { state } = useLocation(); 

  // const { value } = state || {};

  // {cookies.LOGGEDINFO!==undefined||cookies.LOGGEDINFO!==''?id = cookies[0].LOGGEDINFO.user_id:'0'}
  // const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:0;
  const id = (Object.keys(cookies).length > 0 && cookies.LOGGEDINFO !==undefined)?cookies.LOGGEDINFO.user_id:(cookies.GUESTINFO !==undefined)?cookies.GUESTINFO.user_id:0;

  const getproducts = ()=>{
    // setLoading(true);
    getAllProducts()
      .then(response => {
        if(response.status=== 200 && response.products.length > 0)
        setProduct(response.products);
    },)
    // setLoading(false);
    return [product];
  }
  const productCount = (value) => {
    // const id = cookies[0].LOGGEDINFO.user_id;
    postCartCount(id)
    .then(response => {
      if(response.status=== 200)
      {
        respCount(response.quantity_list);
        
      }
    },)
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if(cookieData.sessId !== undefined || cookieData.sessId !=='')
      {
        const id = cookieData.sessId;
        postCartCount(id)
        .then(response => {
          if(response.status=== 200)
          {
            respCount(response.quantity_list);
            // console.log('count',responseCount);
          }
        },
        )
      }
    }
  }
  useEffect(() => {
    // setLoading(true);
    getproducts()
    // setLoading(false);
    productCount(id)
  },[]);
  
  const handleOpen = (value) => {
    // const id = cookies[0].LOGGEDINFO.user_id;
    postCartCount(id)
      .then(response => {
        if(response.status=== 200)
        {
          respCount(response.quantity_list);
        }
      },)
    let p_id = value.id;
    postCartSave(id,p_id)
    .then(response => {
      if(response.status=== 200)
      {
        respSuccess(response.message);
        toggleShowA()
        productCount(id)
      }
    },)
    if (id===0)
    {
      const cookieData = {};
      cookieData.sessId = cookies.SESSIONINFO;
      if (cookieData.sessId === undefined || cookieData.sessId ==='')
      {
        const unique_id = uuid();
        setCookie('SESSIONINFO', JSON.stringify(unique_id));
        cookieData.sessId = unique_id;
        
      }
      if(cookieData.sessId !== undefined || cookieData.sessId !==''){
        const id = cookieData.sessId;
        postCartSave(id,p_id)
        .then(response => {
          if(response.status=== 200)
          {
            respSuccess(response.message);
            toggleShowA()
            productCount(id)
          }
        },)
      }
    }
  }
  const getLoaderList = ()=>{
    const content=[];
    product.map((value,index) => (
      content.push(<Col lg={4}>
        <div className="product-skeleton center">
          <div className="imageskl skeleton"></div>
          <div className="headingskl skeleton"></div>
          <div className="descskl w80 skeleton"></div>
          <Row>
            <Col lg={6}>
              <div className="buttonskl skeleton"></div>
            </Col>
            <Col lg={6}>
              <div className="buttonskl skeleton"></div>
            </Col>
          </Row>
        </div>  
      </Col>)
    ))
    setTimeout(()=>{
      setLoading(true);
    },1500)
    return content;
  }
  const getproductlist = ()=>{
    const content=[];
    product.map((value,index) => (
      content.push(<Col lg={4} md={6} xs={12}>
        <div className="product-list-box">
          <div className="image">
            <Link key={index} to={`/product-detail?#${value.product_name}`.toLowerCase().split(' ').join('-')} state={{value}}><img src={value.product_image} key={index} className="img-fluid" alt="" title=""></img></Link>
          </div>
          <div className="content">
            <h4><Link to={`/product-detail?#${value.product_name}`.toLowerCase().split(' ').join('-')} state={{value}}>{value.product_name}</Link></h4>
            <p>Selling Price <strong> ₹ {value.selling_price!==null?value.selling_price:value.actual_price}</strong> {value.selling_price!==null?<strike>{value.actual_price}</strike>:''} {value.selling_price!==null?getdiscount(value):''}</p>
            <div className="btn-list mt-3">
              <Link className="btn btn-outline-primary btn-lg mr-15" to={`/product-detail?#${value.product_name}`.toLowerCase().split(' ').join('-')} state={{value}}>See More</Link>
              {/* <Link className="btn btn-primary btn-lg" onClick = {(e) => handleOpen(value)}>Add to Cart</Link> */}
              <Button className='btn btn-primary btn-lg' onClick={(e) => handleOpen(value)}>Add to Cart</Button>

            </div>
          </div>
        </div>
      </Col>)
    ))
    return content;
  }
  return (
    <div>
        <Header cartCount={responseCount}/>
        <Toast show={showA} onClose={toggleShowA} className='toast-success' delay={1500} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">item added successfully</strong>
          </Toast.Header>
        </Toast>
        <section className="inner-banner">
          <Container fluid="md">
            <Row>
              <Col lg={6} className="offset-lg-3">
                <div className="inner-cont">
                  <h1>Products</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Products</Breadcrumb.Item>
                  </Breadcrumb>			
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section pb-5">
          <Container fluid="md">

            <Row>
             {isLoading===false?getLoaderList():
              getproductlist()}
            </Row>
            {/* <Row>
              <Col lg={4}>
                <div className="product-skeleton center">
                  <div className="imageskl skeleton"></div>
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                  </Row>
                </div>  
              </Col>
              <Col lg={4}>
                <div className="product-skeleton center">
                  <div className="imageskl skeleton"></div>
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                  </Row>
                </div>  
              </Col>
              <Col lg={4}>
                <div className="product-skeleton center">
                  <div className="imageskl skeleton"></div>
                  <div className="headingskl skeleton"></div>
                  <div className="descskl w80 skeleton"></div>
                  <Row>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                    <Col lg={6}>
                      <div className="buttonskl skeleton"></div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
            {/* {responseCount} */}

            <div className="cart-empty d-none">
              <img src="assets/images/no-record.png" className="img-fluid" alt="" title=""></img>
              <h5 className='mt-3'>No Record Found...</h5>
            </div>
              
          </Container>
        </section>
        <Footer />
    </div>
  );
};

export default ProductList;
