import React,{useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { postTestimonialListing } from "../services/serviceapi";

const Testimonials = () => {
  const [testList, setTestList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const fetchTestimonial = ()=>{
    postTestimonialListing()
    .then(response => {
      if(response.status=== 200)
      {
        setTestList(response.State_list);
      }
    },)
  }
  useEffect(() => {
    fetchTestimonial()
  },[]);
  const getLoaderList = ()=>{
    const content = [];
    testList.map((value,index) => (
      content.push(<Row>
        <Col lg={2}>
          <div className="product-skeleton skeleton">
            <div className="thumb-col3"></div>
          </div>
        </Col>
        <Col lg={10}>                 
          <div className="product-skeleton">
            <div className="descskl skeleton"></div>
            <div className="descskl skeleton"></div>
            <div className="descskl skeleton"></div>
            <div className="descskl skeleton"></div>
            <div className="descskl skeleton"></div>
            <div className="descskl w70 skeleton"></div> 
          </div>
        </Col>
      </Row>
	    )
    ))
    setTimeout(()=>{
      setLoading(true);
    },1200)
    return content;
  }
  const getTestimonialListing = ()=>{
    // fetchTestimonial()
    const content=[];
    testList.map((value,index) => (
      content.push(<Carousel.Item>
        <Row>
          <Col lg={3} md={4}>
          <div className="testi-img"><img src={value.testimonial_img?value.testimonial_img:"/assets/images/testimonial-dummy.png"} className="w-100" alt="" /></div>
          </Col>
          <Col lg={9} md={8}>
            <div className="testi-desc">
              <p>{value.comments}</p>
              <h3>{value.testimonial_name} <span>{value.testimonial_designation}</span></h3>
            </div>
          </Col>
        </Row>
      </Carousel.Item>
	    )
    ))
    return content;
  }
	return (		
		  
        
    <section className="section testimonials">
    <Container fluid="md">
      <Row>
        <Col>
            <div className="testimonials-container">
              <h3 className="testimonial-head d-none d-lg-block">Testimonial</h3>
              <div className="quote d-none d-lg-block"><img src="assets/images/icon-quotes.svg" className="w-100" alt="" /></div>                  
              <Row>
                <Col lg={10} className="offset-lg-2 offsettesti">
                  <h4 className="testimonial-title">What People Say</h4>
                  <Carousel controls={false}>
                      {isLoading===false?getLoaderList():getTestimonialListing()}
                    {/* <Carousel.Item>
                      <Row>
                        <Col lg={3} md={4}>
                        <div className="testi-img"><img src="assets/images/user1.png" className="w-100" alt="" /></div>
                        </Col>
                        <Col lg={7} md={8}>
                          <div className="testi-desc">
                            <p>Mayilon Food Products are good healthcare products, no side effects. 100% ayurvedic medicines.</p>
                            <h3>Dr. Victor <span>Healthcare Specialist</span></h3>
                          </div>
                        </Col>
                      </Row>
                    </Carousel.Item> */}
                  </Carousel>
                </Col>
              </Row>
              
            </div>
        </Col>
      </Row>
    </Container>
  </section>
	);
};

export default Testimonials;
