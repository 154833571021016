import React,{useState,useEffect} from "react";
import { Link,useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Icon } from '@iconify/react';

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { postresetpassword } from "../services/serviceapi";

const ConfirmPassword = () => {  
  const queryParams = new URLSearchParams(window.location.search)
  const mobile = queryParams.get('mob_num');
  const navigate = useNavigate();
  const [newpasswordType, setNewPasswordType] = useState("password");
  const [confirmpasswordType, setConfirmPasswordType] = useState("password");
  const [newpassword, setNewPwd] = useState("");
  const [confirmpassword, setConfirmPwd] = useState("");
  const defData = {newpassword:'',confirmpassword:''};
  const [formErrors,formError] = useState(defData);
  const [passwordSuccess,setPwdSuccessMessage] = useState("");
  const [passwordFail,setPwdFailMessage] = useState("");
  // console.log(newpassword,'new password');
  const toggleNewPassword =()=>{
    if(newpasswordType==="password")
    {
     setNewPasswordType("text");
    }else{
      setNewPasswordType("password");
    }
  }
  const toggleConfirmPassword =()=>{
    if(confirmpasswordType==="password")
    {
     setConfirmPasswordType("text");
    }else{
      setConfirmPasswordType("password");
    }
  }
  const onChange = (e) => {
    const eTarget = e.target;
    switch(eTarget.name) {
    case 'newpassword':
      formError('');
      setNewPwd(eTarget.value)
    break;
   
    case 'confirmpassword':
      formError('');
      setConfirmPwd(eTarget.value)
    break;
    
    default:
    break;
   }
 }
 function isCheckPassword(str)
  {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return re.test(str);
  }
  const onSubmit = (e) => {
    const error = {}
   
    if(newpassword==='') error.newpassword = 'Enter New Password'
    else if(newpassword!=="" && !isCheckPassword(newpassword))
    {
      error.newpassword = ' Password should have atleast 6 characters includes atleast one symbol, caps and numeric';
    }
    if(confirmpassword==='') error.confirmpassword = 'Enter Confirm Password'
    else if(confirmpassword!=="" && !isCheckPassword(confirmpassword))
    {
      error.confirmpassword = ' Password should have atleast 6 characters includes atleast one symbol, caps and numeric';
    }
    else if(newpassword!==confirmpassword)
    {
      error.confirmpassword = "Password doesn't match";
    }
    formError(error)
    if(Object.keys(error).length === 0 && newpassword===confirmpassword)
    {
      postresetpassword(mobile,newpassword)
      .then(response => {
        if(response.status=== 200)
        {
          setPwdSuccessMessage(response.message)
          setNewPwd('');
          setConfirmPwd('');
          let path = "/login"; 
          
          navigate(path);
          
        }
        else
        {
          setPwdFailMessage(response.message)
        }
      },)
    }
  }
  useEffect(() => {
    setNewPwd('');
    setConfirmPwd('');
    
  },[]);

  return (
    <div>
        <Header />
        <section className="section header-nxt otp-page">
          <Container fluid="md">
            <Row>            
              <Col lg={4} className="offset-lg-4">
                <div className="login-cont">
                  <h3>New Password</h3>
                  <Form className="mt-4">

                    <Form.Group className="form-group mb-3" controlId="formBasicPassword">
                      <Form.Label>Password<span className="req">*</span></Form.Label>
                      <Form.Control type={newpasswordType} name="newpassword" value={newpassword} onChange={onChange} autoComplete="off" maxLength="20" placeholder="New password" />
                      <div onClick={toggleNewPassword} className="eye">
                        {(newpasswordType==="password")?(
                         <Icon icon="mdi:eye-off" />):
                        <Icon icon="mdi:eye" />}
                      </div>
                      {formErrors.newpassword!=='' && formErrors.newpassword!==undefined && (<Form.Text className='error text-danger'>{formErrors.newpassword}</Form.Text>)}
                    </Form.Group>
                    <Form.Group className="form-group form-group-input mb-1" controlId="formBasicPassword">
                      <Form.Label>Confirm Password <span className="req">*</span></Form.Label>
                      <Form.Control type={confirmpasswordType} name="confirmpassword" 
                      value={confirmpassword} onChange={onChange} autoComplete="off" maxLength="20"
                        placeholder="Confirm password" />
                      <div onClick={toggleConfirmPassword} className="eye">
                      {(confirmpasswordType==="password")?(
                         <Icon icon="mdi:eye-off" />):
                        <Icon icon="mdi:eye" />}
                        </div>
                      {formErrors.confirmpassword!=='' && formErrors.confirmpassword!==undefined && (<Form.Text className='error text-danger'>{formErrors.confirmpassword}</Form.Text>)}
                      {passwordFail !=='' && passwordFail !==undefined && (<Form.Text className='error text-danger'>{passwordFail}</Form.Text>)}
                    </Form.Group>
                    <div className="forgot-row mt-2 mb-3">
                      {/* <Link to="/forgotpassword" className="fwd-password">Forgot Password?</Link> */}
                    </div>
                    <Button variant="primary" className="btn-lg w-100" onClick={onSubmit}>Submit</Button>                    
                  </Form>
                  <p className="reg-text">Back to <Link to="/login">Sign In?</Link></p>
                </div>                
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
    </div>
  );
};

export default ConfirmPassword;
